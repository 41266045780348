<template>
    <div>
        <AppBar />
        <div class="overflow-y-auto h100vh ss-cust-scroll overflow-x-hidden">
            <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
                <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
                    <div class="mb-6 pos-rlt">
                        <v-toolbar class="d-none d-md-block px-0 pos-stk top-68 z-i3 tool-sty" flat dense color="#fff">
                            <v-row>
                                <v-col cols="12" md="9" class="px-0">
                                    <v-tabs @change="setbodyTab" fixed color="#000000" v-model="bodytab" show-arrows
                                        dense>
                                        <v-tab dense v-for="(t, index) in dashitems" :key="index"
                                            class="text-none text-start min-w-fit">
                                            <span class="text-center tab-txt">{{ t.txt }}</span></v-tab>
                                        <v-tabs-slider color="#000000"></v-tabs-slider>
                                    </v-tabs>
                                </v-col>
                                <v-col cols="12" md="3" class="text-right mt-auto pr-0">
                                    <p class="mb-3 tab-end-side"><v-icon color="#1FD601"
                                            size="6">mdi-checkbox-blank-circle</v-icon>
                                        Market live
                                    </p>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <!-- <v-progress-linear v-if="mainloader" color="primary" class="pos-stk z-i3 top-116"
                            indeterminate></v-progress-linear> -->
                        <v-divider class="mb-md-4 mb-3 d-none d-md-flex pos-stk z-i3 top-116"></v-divider>
                        <v-card class="elevation-0 rounded-0 pos-fix z-i3 pa-2 top-56 rig-0 d-none d-md-flex"
                            width="100%" color="#fff"></v-card>
                        <div>
                            <v-row no-glutters>
                                <v-col cols="12" md="9" class="px-md-0 pt-4 pt-md-3 pb-0 pb-md-3">
                                    <v-card :loading="mainloader" id="overview" class="ss-cards overflow-hidden mb-md-6"
                                        width="100%">
                                        <v-card color="#FAFBFF" class="elevation-0 rounded-0 pt-2 px-md-2 px-3">
                                            <v-row no-glutters>
                                                <v-col cols="12" md="7" class="pb-1 pb-md-2">
                                                    <v-list-item class="px-0 px-md-4">
                                                        <v-list-item-avatar color="#FFFFFF" size="56" class="my-0">
                                                            <img :src="singlepageres.image_link" class="pa-1"
                                                                width="100%" tile contain />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="pt-3 pb-0">
                                                            <v-list-item-title
                                                                class="fs-18 font-weight-bold mb-0 text-capitalize lh-14">
                                                                {{ singlepageres["Company Name"] ?
                                                                    singlepageres["Company Name"] : 'abcd'
                                                                }}</v-list-item-title>

                                                            <v-chip-group class="my-0 py-0">
                                                                <v-chip label small text-color="#666666" color="#fff"
                                                                    class="font-weight-medium fs-10">
                                                                    {{ singlepageres["Stock Exchanges"] ?
                                                                        singlepageres["Stock Exchanges"] : '' }}
                                                                </v-chip>

                                                            </v-chip-group>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                                <!-- <v-col cols="12" md="5"
                                                    class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
                                                    <p class="mb-0 mr-md-4 subtitle-1 font-weight-bold lh-24">
                                                        <br class="d-none d-md-block">
                                                        <span class="fs-12"
                                                            :class="menudata.ch > 0 ? 'txt-gre' : menudata.ch < 0 ? 'txt-red' : 'txt-444'">
                                                            %</span>
                                                    </p>
                                                </v-col> -->
                                            </v-row>
                                        </v-card>
                                        <div class="py-3 py-md-6">
                                            <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                                dense>
                                                <p class="wid-160 font-weight-bold title mb-0">Price Chart</p>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                            <v-divider></v-divider>
                                            <v-row
                                                class="pt-md-6 pb-md-3 pl-md-6 pr-md-8 flex-column-reverse flex-md-row">
                                                <v-col cols="12" md="4" class="px-7 px-md-3">
                                                    <v-row no-gluttters>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Market
                                                                        Cap
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                        Cr</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Volume
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Open
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Close
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>

                                                        <v-col cols="12">
                                                            <p class="font-weight-regular fs-10 txt-666 mb-0">
                                                                High - Low</p>

                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <p class="font-weight-regular fs-10 txt-666 mb-0">52
                                                                Weeks high - 52 Weeks
                                                                low</p>

                                                            <v-divider></v-divider>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" md="8" class="pa-md-0 pt-4 pb-0 px-6">

                                                </v-col>
                                            </v-row>
                                        </div>

                                    </v-card>
                                    <v-card id="fun" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="py-3 py-md-6">
                                            <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                                dense>
                                                <v-list-item class="px-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold title mb-2 ">IPO
                                                            Timeline</v-list-item-title>

                                                        <!-- <v-list-item-title
                                                            class="black--text font-weight-medium fs-12">Fundamental
                                                            breakdown of

                                                            information</v-list-item-title> -->
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-toolbar>
                                            <div class="timeline-horizontal  px-7">
                                                <div v-for="(event, index) in timelineEvents" :key="index"
                                                    :class="['event', event.status]">
                                                    <span
                                                        :class="['dot', event.status === 'completed' ? 'completed-dot' : 'dotnot']">
                                                        <v-icon size="25"
                                                            :style="{ color: event.status === 'completed' ? '#2196f3' : 'grey' }">
                                                            {{ event.status === 'completed' ?
                                                                'mdi-check-circle' : 'mdi-circle' }}</v-icon>
                                                    </span>
                                                    <span class="timeheadd mt-3">{{ event.name }}</span>
                                                    <span class="timelinevalue">{{ event.datess }}</span>
                                                </div>
                                            </div>


                                        </div>
                                    </v-card>
                                    <v-card id="fin" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-md-6 pt-4 px-4 px-md-6">
                                            <v-list-item class="px-0">
                                                <v-list-item-content class="pt-0">
                                                    <v-list-item-title
                                                        class="font-weight-bold title mb-2">Financial</v-list-item-title>

                                                </v-list-item-content>
                                            </v-list-item>

                                        </div>
                                        <!-- <div ref="chart" style="width: 100%; height: 400px;"></div> -->
                                        <!-- <v-card height="420px" id="mainnew" class="rounded-lg pt-4 elevation-0">
                                        </v-card> -->
                                        <v-card id="mainnew" style="width: 100%; height: 500px;" class="pa-4"></v-card>

                                        <v-divider class="d-md-none"></v-divider>
                                    </v-card>
                                    <v-card id="peers" class="ss-cards overflow-hidden mb-md-6 pt-6" width="100%">
                                        <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                            dense>
                                            <v-list-item class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold title mb-2">Peers
                                                        Comparison</v-list-item-title>
                                                    <v-list-item-title
                                                        class="black--text font-weight-medium fs-12">Peers Comparison
                                                        breakdown of
                                                        information</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-spacer></v-spacer>
                                            <!-- <v-text-field v-model="peersearch" hide-details
                                                prepend-inner-icon="mdi-magnify" label="Search"
                                                class="rounded-pill mb-2 d-none d-md-flex" dense flat solo
                                                background-color="#F1F3F8"></v-text-field> -->
                                        </v-toolbar>

                                        <v-divider class="d-md-none"></v-divider>
                                        <!-- <v-text-field hide-details prepend-inner-icon="mdi-magnify" label="Search for stocks to add"
                                class="rounded-pill mr-4 mx-8 mb-6 max-w-28" dense flat solo
                                background-color="#F1F3F8"></v-text-field> -->
                                    </v-card>
                                    <v-card id="price" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-6  pl-md-6 pl-4">
                                            <v-toolbar flat dense class="tool-sty mb-2">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold title mb-2">
                                                            Promoters Holding</v-list-item-title>

                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>

                                        </div>
                                        <v-data-table mobile-breakpoint item-key="name" must-sort hide-default-footer
                                            fixed-header class="px-6 pb-6 financialtabel rounded-0"
                                            :headers="financialheader"
                                            :items="singlepageres.script_data.IPO_Promoter_Holding"
                                            :items-per-page="-1">

                                            <template v-slot:no-data>
                                                <v-col cols="12" class="text-center pa-16">
                                                    <div class="mx-auto ">
                                                        <img class="align-self-stretch mx-auto" width="80px"
                                                            :src="require('@/assets/no data folder.svg')"
                                                            alt="no data" />
                                                        <h5 class="txt-999 font-weight-regular">There is no
                                                            data here yet!
                                                        </h5>

                                                    </div>
                                                </v-col>
                                            </template>
                                        </v-data-table>
                                    </v-card>


                                </v-col>
                                <v-col cols="12" md="3" class="pr-md-0 pt-0 pt-md-3">
                                    <div class="pos-md-stk top-md-134 px-4 px-md-0">
                                        <v-card outlined
                                            class="overflow-hidden ss-login-card pt-md-3 px-3 rounded-lg text-center pos-md-rlt"
                                            width="100%">
                                            <img src="@/assets/products-sec/Stocks-ETFs.png" alt="Stocks-ETFs"
                                                class="mb-md-2 mt-md-4 px-md-4 px-16" width="100%">
                                            <p class="font-weight-bold fs-16 mb-1">Ready to invest in IPOS?</p>
                                            <p class="txt-666 font-weight-medium fs-12 mb-8">Open your demat account
                                                effortlessly <br> with our six-click
                                                process.
                                            </p>
                                            <v-card class="pos-md-abs pt-6 pt-md-0 btm-32 elevation-0 rounded-0"
                                                width="100%">
                                                <v-form ref="form" v-model="valid" lazy-validation
                                                    @submit.prevent="getCall()" class="pr-md-6">

                                                    <v-text-field v-model="mobile" @keypress="NumberValid($event)"
                                                        @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                                                        required hide-spin-buttons flat hide-details solo
                                                        background-color="#F1F3F8"
                                                        class="sign-up-filed elevation-0 rounded-pill caption mb-3"
                                                        label="Enter mobile number to begin">
                                                        <template #prepend-inner>
                                                            <img src="@/assets/phone-icon.svg" width="100%"
                                                                alt="phone-icon">
                                                        </template>
                                                    </v-text-field>

                                                    <v-btn type="submit" block height="48px"
                                                        :color="valid ? '#FFF07E' : '#FFF07E30'"
                                                        class="text-none rounded-pill elevation-0"><span
                                                            class="black--text font-weight-bold">Invest
                                                            now</span></v-btn>
                                                </v-form>
                                            </v-card>

                                            <!-- <img src="@/assets/sstk/ssimgb.png" alt="ssimgb" class="pos-abs btm-0 rig-0"
                                                width="100%"> -->
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                </div>
            </v-container>
            <div class="sec-footer">
                <FooterSet />
            </div>
        </div>
    </div>
</template>


<script>
// import axios from 'axios';
import AppBar from "../../components/AppbarSet.vue";
import FooterSet from '../../components/FooterSet.vue';
import * as echarts from "echarts";

export default {

    components: {
        FooterSet,
        AppBar
    },
    name: "FinancialChart",
    data: () => ({
        dashitems: [
            { txt: 'Overview' },
            { txt: 'Fundamental' },
            { txt: 'Financials' },
            { txt: 'Peers' },
            { txt: 'Holdings' },
            { txt: 'Events' },
            { txt: 'News' },
        ],
        menudata: {},
        mainloader: true,
        bodytab: 0,

        financialheader: [
            { text: 'Investors', value: 'name', sortable: false },
            { text: 'Holdings %', value: 'value', sortable: false, align: 'right' },
        ],
        singlepageres: [],
        valid: true,
        mobile: '',
        numberis: [
            (v) => !!v || "Your mobile number is required",
            (v) =>
                /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
        ],
        timelineEvents: [],
        chartIdnew: "mainnew",
    }),

    mounted() {
        this.singlepagedata()
    },

    methods: {

        setbodyTab() {
            var element;
            if (this.bodytab == 0) {
                element = document.getElementById('overview')
            }
            else if (this.bodytab == 1) {
                element = document.getElementById('fun')
            }
            else if (this.bodytab == 2) {
                element = document.getElementById('fin')
            }
            else if (this.bodytab == 3) {
                element = document.getElementById('peers')
            }
            else if (this.bodytab == 4) {
                element = document.getElementById('hold')
            }
            else if (this.bodytab == 5) {
                element = document.getElementById('event')
            }
            else if (this.bodytab == 6) {
                element = document.getElementById('news')
            }
            element.scrollIntoView({ behavior: 'smooth' });
        },
        singlepagedata() {
            this.assresdata = []
            this.networtdata = []
            this.datedata = []
            this.profitaftax = []
            this.revesers = []
            this.revenvedata = []
            this.totbrowindata = []
            this.singlepageres = []
            const axios = require('axios');
            let data = JSON.stringify({
                "company_name": "Emerald Tyre Manufacturers Limited"
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://v3.mynt.in/ipo/get_single_ipo_data',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.singlepageres = response.data.data
                    axiosThis.mainloader = false

                    const ipoDetails = axiosThis.singlepageres.script_data.IPO_Details || [];
                    const ipoTimeline = axiosThis.singlepageres.script_data.IPO_Timeline || [];



                    const allDetails = [...ipoDetails, ...ipoTimeline];

                    const ipoTimelineDetails = {
                        "IPO Start Date": allDetails.find(detail => detail.name === "IPO Start Date")?.value,
                        "IPO End Date": allDetails.find(detail => detail.name === "IPO End Date")?.value,
                        "Listing Date": ipoTimeline.find(detail => detail.name === "Listing Date")?.value,
                        "Basis of Allotment": allDetails.find(detail => detail.name === "Basis of Allotment")?.value,
                        "Initiation of Refunds": allDetails.find(detail => detail.name === "Initiation of Refunds")?.value,
                        "Credit of Shares to Demat": allDetails.find(detail => detail.name === "Credit of Shares to Demat")?.value,
                        "Face Value": allDetails.find(detail => detail.name === "Face Value")?.value,
                        "Price Band": allDetails.find(detail => detail.name === "Price Band")?.value,
                        "Lot Size": allDetails.find(detail => detail.name === "Lot Size")?.value,
                        "Total Issue Size": allDetails.find(detail => detail.name === "Total Issue Size")?.value,
                        "Fresh Issue": allDetails.find(detail => detail.name === "Fresh Issue")?.value,
                        "Offer for Sale": allDetails.find(detail => detail.name === "Offer for Sale")?.value,
                        "Issue Type": allDetails.find(detail => detail.name === "Issue Type")?.value,
                        "Listing At": allDetails.find(detail => detail.name === "Listing At")?.value,
                        "Share holding previous issue": allDetails.find(detail => detail.name === "Share holding pre issue")?.value,
                        "Share holding Post issue": allDetails.find(detail => detail.name === "Share holding post issue")?.value,
                        "Market Maker portion": allDetails.find(detail => detail.name === "Market Maker portion")?.value,
                    };

                    axiosThis.details = {
                        ipoTimelineDetails: ipoTimelineDetails,
                    };

                    axiosThis.newtimelindata = {
                        "IPO Start Date": allDetails.find(detail => detail.name === "IPO Start Date")?.value,
                        "IPO End Date": allDetails.find(detail => detail.name === "IPO End Date")?.value,
                        "Basis of Allotment": allDetails.find(detail => detail.name === "Basis of Allotment")?.value,
                        "Initiation of Refunds": allDetails.find(detail => detail.name === "Initiation of Refunds")?.value,
                        "Credit of Shares to Demat": allDetails.find(detail => detail.name === "Credit of Shares to Demat")?.value,
                        "Listing Date": ipoTimeline.find(detail => detail.name === "Listing Date")?.value,
                    }
                    this.processResponse()
                    // axiosThis.datedata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                    //     parseFloat(element['Period Ended'])
                    // );
                    axiosThis.datedata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) => {
                        return element['Period Ended'];
                    });

                    axiosThis.assresdata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element.Assets)
                    );
                    axiosThis.networtdata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element["Net Worth"])
                    );
                    axiosThis.profitaftax = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element["Profit After Tax"])
                    );
                    axiosThis.revesers = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element["Reserves and Surplus"])
                    );

                    axiosThis.revenvedata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element.Revenue)
                    );
                    axiosThis.totbrowindata = axiosThis.singlepageres.script_data.IPO_Financial_Information.map((element) =>
                        parseFloat(element["Total Borrowing"]

                        )
                    );

                    axiosThis.initChart(
                        axiosThis.datedata,
                        axiosThis.assresdata,
                        axiosThis.networtdata,
                        axiosThis.profitaftax,
                        axiosThis.revesers,
                        axiosThis.revenvedata,
                        axiosThis.totbrowindata,

                    );
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.mainloader = false
                });

        },
        processResponse() {
            const today = new Date();
            this.timelineEvents = Object.keys(this.newtimelindata).map((key) => {
                const eventDate = new Date(this.newtimelindata[key]);
                const status =
                    eventDate < today
                        ? "completed"
                        : eventDate.toDateString() === today.toDateString()
                            ? "current"
                            : "not-achieved";

                return {
                    name: key,
                    status,
                    datess: this.newtimelindata[key],
                };
            });
        },
        initChart(one, two, three, four, five, six, seven) {
            // console.log("one", one);
            // console.log("two", two);
            // console.log("three", three);
            // console.log("four", four);
            // console.log("five", five);
            // console.log("six", six);
            // console.log("seven", seven);

            this.$nextTick(() => {
                if (this.chartInstancenew) {
                    this.chartInstancenew.dispose();
                }

                const chartContainer = document.getElementById("mainnew");
                if (!chartContainer) {
                    console.error("Chart container not found!");
                    return;
                }
                const colors = ["#148564", "#7CD36E" , "#F9CD6C" , "#FDEBC4" , "#DEDEDE"];
                this.chartInstancenew = echarts.init(chartContainer);

                const labelOption = {
                    show: false, // Disables the labels on the bars
                };

                const option = {
                    color: colors,
                    title: {
                        // text: "IPO Financial Information",
                        left: "center",
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                             
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        axisTick: { show: false },
                        data: one,
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: [
                        {
                            name: "Assets",
                            type: "bar",
                            barGap: 0,
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: two,
                        },
                        {
                            name: "Net Worth",
                            type: "bar",
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: three,
                        },
                        {
                            name: "Profit After Tax",
                            type: "bar",
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: four,
                        },
                        {
                            name: "Reserves and Surplus",
                            type: "bar",
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: five,
                        },
                        {
                            name: "Revenue",
                            type: "bar",
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: six,
                        },
                        {
                            name: "Total Borrowing",
                            type: "bar",
                            label: labelOption,
                            emphasis: {
                                focus: "series",
                            },
                            data: seven,
                        },
                    ],
                    legend: {
    data: [
        "Assets",
        "Net Worth",
        "Profit After Tax",
        "Reserves and Surplus",
        "Revenue",
        "Total Borrowing",
    ],
    orient: "horizontal", // Arrange items horizontally
    bottom: -4,           // Distance from the bottom of the chart container
    top: null             // Ensure no conflict with bottom positioning
},
                };

                this.chartInstancenew.setOption(option);
            });
        }



    }
}
</script>

<style>
@import '../../assets/style/style.css';


.TL-wrapper {
    padding: 14px;
    border: 2px solid #ccc;
    margin-bottom: 14px;
}

.timeline-horizontal {
    display: flex;
}

.event {
    flex: 1 0 120px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.event .dot {
    display: inline-block;
    width: 23px;
    height: 23px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.event:not(:last-child):after {
    content: "";
    border: 1px solid #ccc;
    position: absolute;
    width: calc(100% - 18px);
    top: 7px;
    left: 17px;
    margin-top: 6px;
    margin-left: 5px;

}

.event .stage-name {
    max-width: max(0px, calc((100% - 120px)*999));
    overflow: hidden;
}

.contained-in-width {
    width: 400px;
}

.event>div {
    width: 100%;
    display: flex;
}

.event.completed .dot {
    border-color: #ffffff;

}

.event.completed:not(:last-child):after {
    border-color: #2196f3;
    margin-top: 6px;
    margin-left: 3px;
}

.event.current .dot {
    border-color: #2196f3;
    background-color: #2196f3;


}

.event.current .stage-name {
    max-width: none;
    overflow: initial;
}
</style>