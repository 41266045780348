<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Disposal of Complaints</p>

                <div class="d-inline-flex mb-6">
                    <v-btn @click="activePage(i = p)" v-for="(p, b) in complaintitems" :key="b" medium
                        :color="p == pagename ? '#ffffff' : '#dddddd'" class="rounded-pill mr-2 text-none brd-2"
                        outlined>
                        {{ p }}
                    </v-btn>
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 text-center d-md-none">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Disposal of Complaints</p>

                <div>
                    <v-btn @click="activePage(i = p)" v-for="(p, b) in complaintitems" :key="b" small
                        :color="p == pagename ? '#ffffff' : '#dddddd'" class="rounded-pill mr-2 mb-6 text-none brd-2"
                        outlined>
                        {{ p }}
                    </v-btn>
                </div>
            </v-card>
        </div>
        <div class="px-6 px-sm-8 px-md-14 py-6 py-md-16 mb-6">
            <p class="font-weight-bold title">Exchange investor charter Data for month ending {{ new Date(new
                        Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', {
                            month: 'long', year: 'numeric'
                        })
                }}</p>
            <v-card width="100%" class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl">
                <div class="cnt-brk-1">
                    <tbody>
                        <tr class="text-left">
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">SN</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Received
                                    from</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">carried forward
                                    from previous
                                    month</span>
                            </th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Received during
                                    the
                                    month</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Total
                                    pending</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Resolved</span>
                            </th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Pending at the
                                    end
                                    of the month**
                                    (**Should
                                    include total
                                    complaints pending as on the last day of the month, if any)</span></th>
                            <th class="px-2 pt-4 va-t brt-b"><span class="font-weight-bold subtitle-1">Average
                                    Resolution
                                    time^
                                    (in days)
                                    ^Average
                                    resolution
                                    time is the sum total of time taken to resolve each complaint in the current month
                                    divided
                                    by
                                    total number of complaints resolved in the current month.</span></th>
                        </tr>
                        <tr class="text-left px-3">
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2 py-3  brt-b">
                                <v-row>
                                    <v-col cols="6"><span class="font-weight-bold subtitle-1">Pending for less
                                            than 3
                                            month</span></v-col>
                                    <v-col cols="6">
                                        <span class="font-weight-bold subtitle-1">Pending for more than 3
                                            month</span>
                                    </v-col>
                                </v-row>
                            </th>
                            <th class="px-2 brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                        </tr>

                        <tr class="text-center px-3"
                            v-for="(l, j) in pagename == 'TM' ? tmICitems : pagename == 'DP' ? dpICitems : pagename == 'Research Analyst' ? raICitems : iaICitems"
                            :key="j">
                            <th class="px-2 py-3 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.SN }}</span></th>
                            <th class="px-2 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.Refrom }}</span></th>
                            <th class="px-2 py-3 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.carriedfrdpremont
                                    }}</span></th>

                            <th class="px-2 py-3 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.RevedduringMonth
                                    }}</span></th>
                            <th class="px-2 py-3 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.totalpend }}</span>
                            </th>
                            <th class="px-2 py-3 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.Resolved }}</span></th>

                            <th class="px-2 " :class="tmICitems.length - 1 != j ? 'brt-b' : ''">
                                <v-row>
                                    <v-col cols="6"><span class="font-weight-regular txt-444">{{
                        l.pendingLesThanMonth }}</span></v-col>
                                    <v-col cols="6">
                                        <span class="font-weight-regular txt-444">{{
                        l.pendingMoreThanMonth }}</span>
                                    </v-col>
                                </v-row>
                            </th>
                            <th class="px-2 py-3" :class="tmICitems.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.AvageSolution }}</span></th>
                        </tr>
                    </tbody>
                </div>
            </v-card>
            <p class="font-weight-bold title mt-10">Trend of monthly disposal of complaints</p>
            <v-card class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl" outlined>
                <div class="cnt-brk-1">
                    <v-row no-glutters class="py-3">
                        <v-col cols="4">
                            <v-row no-glutters>
                                <v-col cols="2">
                                    <span class="font-weight-bold subtitle-1 ml-3">SN</span>
                                </v-col>
                                <v-col cols="3">
                                    <span class="font-weight-bold subtitle-1">Year from</span>
                                </v-col>
                                <v-col cols="7">
                                    <span class="font-weight-bold subtitle-1">carried forward from previous month</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-row no-glutters>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Received during the month</span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Resolved during the month</span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Pending at the end of the month</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div v-for="(l, j) in pagename == 'TM' ? tmMonthlyDisposal : pagename == 'DP' ? dpMonthlyDisposal : pagename == 'Research Analyst' ? raMonthlyDisposal : iaMonthlyDisposal"
                        :key="j">
                        <v-row no-glutters class="text-center py-3" :class="l.Year != 'Grand Total' ? '' : 'd-none'">
                            <v-col cols="4">
                                <v-row no-glutters>
                                    <v-col cols="2">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.SN }}</span></span>
                                    </v-col>
                                    <v-col cols="3">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.Year }}</span></span>
                                    </v-col>
                                    <v-col cols="7">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.carriedfrdpremont
                                                }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="8">
                                <v-row no-glutters>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.RecvedDuringMonth
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.ReSolveddDuringMonth
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.PendEndMonth
                                                }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider
                            v-if="pagename == 'TM' ? tmMonthlyDisposal.length - 2 != j : pagename == 'DP' ? dpMonthlyDisposal.length - 2 != j : raMonthlyDisposal.length - 2 != j"></v-divider>
                        <v-row no-glutters class="text-center py-3" :class="l.Year == 'Grand Total' ? '' : 'd-none'">
                            <v-col cols="4">
                                <v-row no-glutters>
                                    <v-col cols="5">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.Year }}</span></span>
                                    </v-col>
                                    <v-col cols="7">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.carriedfrdpremont
                                                }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="8">
                                <v-row no-glutters>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.RecvedDuringMonth
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.ReSolveddDuringMonth
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.PendEndMonth
                                                }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card>

            <p class="font-weight-bold title mt-10">Trend of annual disposal of complaints</p>
            <v-card class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl" outlined>
                <div class="cnt-brk-1">
                    <v-row no-glutters class="py-3">
                        <v-col cols="4">
                            <v-row no-glutters>
                                <v-col cols="2">
                                    <span class="font-weight-bold subtitle-1 ml-3">SN</span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Year from</span>
                                </v-col>
                                <v-col cols="6">
                                    <span class="font-weight-bold subtitle-1">carried forward from previous year</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-row no-glutters>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Received during the year</span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Resolved during the year</span>
                                </v-col>
                                <v-col cols="4">
                                    <span class="font-weight-bold subtitle-1">Pending at the end of the year</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div v-for="(l, j) in pagename == 'TM' ? tmAnnualDisposal : pagename == 'DP' ? dpAnnualDisposal : pagename == 'Research Analyst' ? raAnnualDisposal : iaAnnualDisposal  "
                        :key="j">
                        <v-row no-glutters class="text-center py-3" :class="l.Year != 'Grand Total' ? '' : 'd-none'">
                            <v-col cols="4">
                                <v-row no-glutters>
                                    <v-col cols="2">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.SN }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.Year }}</span></span>
                                    </v-col>
                                    <v-col cols="6">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.carriedfrdpreYear
                                                }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="8">
                                <v-row no-glutters>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.RecvedDuringYear
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.ReSolveddDuringYear
                                                }}</span></span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="font-weight-bold subtitle-1"><span
                                                class="font-weight-regular txt-444">{{ l.PendEndYear }}</span></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider
                            v-if="pagename == 'TM' ? tmAnnualDisposal.length - 1 != j : pagename == 'DP' ? dpAnnualDisposal.length - 1 != j : raAnnualDisposal.length - 1 != j"></v-divider>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { strapi } from '../../apiurl';

export default {

    data: () => ({
        complaintitems: [
            'TM',
            'DP',
            'Research Analyst',
            'Investment Adviser',

        ],
        pagename: 'TM',
        // Tm

        tmICitems: [],
        tmMonthlyDisposal: [],
        tmAnnualDisposal: [],
        // DP
        dpICitems: [],
        dpMonthlyDisposal: [],
        dpAnnualDisposal: [],
        // RA
        raICitems: [],
        raMonthlyDisposal: [],
        raAnnualDisposal: [],
        // IA
        iaICitems: [],
        iaMonthlyDisposal: [],
        iaAnnualDisposal: []
    }),
    created() {
        let config = {
            method: 'get',
            url: `${strapi}/disposal-jsons`,
        };

        let axiosThis = this;
        axios.request(config)
            .then((response) => {
                if (response.data && response.data.data) {
                    axiosThis.getDisposal(response.data.data[0].attributes);
                }
            })
            .catch(() => {
            });
    },
    methods: {
        activePage(i) {
            this.pagename = i;
        },
        getDisposal(datas) {
            if (datas) {
                this.tmICitems = datas.tm_investor_charter
                this.tmMonthlyDisposal = datas.tm_monthly_disposal
                this.tmAnnualDisposal = datas.tm_annual_disposal

                this.dpICitems = datas.dp_investor_charter
                this.dpMonthlyDisposal = datas.dp_monthly_disposal
                this.dpAnnualDisposal = datas.dp_annual_disposal

                this.raICitems = datas.ra_investor_charter
                this.raMonthlyDisposal = datas.ra_monthly_disposal
                this.raAnnualDisposal = datas.ra_annual_disposal

                this.iaICitems = datas.ia_investor_charter
                this.iaMonthlyDisposal = datas.ia_monthly_disposal
                this.iaAnnualDisposal = datas.ia_annual_disposal
            } else {
                setTimeout(() => {
                    this.getDisposal();
                }, 1000);
            }
        },
    }
}
</script>