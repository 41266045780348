<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Blog Policy</p>
                <p class="white--text headline">Blog policy
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Blog Policy</p>
                <p class="white--text fs-8">Blog policy
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 pa-md-16 mb-6 mx-md-16">
            <div class="px-md-16">
                <p class="mb-0 font-weight-light lh-28">
                <ul>
                    <li>Blogger is a free service for communication, self-expression, and freedom of speech. We believe
                        Blogger
                        increases the availability of information, encourages healthy debate, and makes possible new
                        connections
                        between people. One of the main purposes of encouraging comments on a blog is to promote a sense of
                        community.</li><br>
                    <li>The blog is probably one of the company's valuable marketing tools. It should be used to build
                        trust with our customers, generate leads, educate customers, and build brand awareness. So, if a
                        reader
                        tells us that the blog's content quality is poor, that comment will probably make us cringe just a
                        little.</li><br>

                    <li>The Good company blogs are engaging, deliver value, and align with the company's business strategy.
                        The
                        content which we develop for our blog should help us deliver/develop a consistent brand message.
                    </li>
                </ul>
                </p>
                <br>
                <p class="mb-0 font-weight-bold title">Approval</p>
                <br>
                <p class="mb-0 font-weight-light lh-28">
                <ul>
                    <li>SEBI circular CIR/ISD/1/2011 dated March 23, 2011, on Unauthenticated news circulated by SEBI
                        Registered
                        market intermediaries through various modes of communication.</li><br>
                    <li>It has been observed that unauthenticated news related to various scrips are circulated in
                        blogs/chat
                        forums/e-mail etc. by employees of Broking Houses/OtherIntermediaries without adequate caution are
                        strictly prohibited.</li><br>
                    <li>Access to Blogs/Chat forums/Messenger sites etc. should either be restricted to limited users after
                        getting proper approval from management.</li><br>
                    <li>Blog users should be well informed that any market-related news received by them either in their
                        official mail/personal mail/ or in any other manner, should be forwarded only after the same has
                        been
                        seen and approved by the concerned Intermediary's Compliance Officer. If an employee fails to do so,
                        he/she shall
                        be deemed to have violated the various provisions contained in SEBI Act/Rules/Regulations etc. and
                        shall
                        be liable for action.
                    </li>
                </ul>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
    }),
}
</script>