<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Gratuity calculator</p>
                    <p class="title mb-1">Estimate the gratuity amount you’re entitled to based on your tenure and salary.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Monthly salary(Basic + DA)</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Year of service</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="Yr" v-model="InterestRate" hide-spin-buttons min="1" max="50"
                                        step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="title font-weight-regular">Estimation</p>
                            <v-list-item class="pa-0 mb-8">
                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                    color="#015FEC"></v-card>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total Gratuity payable
                                    </v-list-item-subtitle>
                                    <p class="mb-0 title font-weight-black">&#8377; {{ gratuity ?
                                        gratuity : '0.00'
                                    }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is gratuity?</p>
                    <p class="mb-6 font-weight-light txt-444">A gratuity is the amount of money a company gives to an
                        employee as a "thank you" for work done for the company. But the amount is only given to employees
                        who have worked for the company for at least five years. The Gratuity Act of 1972 is in charge of
                        it.
                        <br><br>
                        If an employee gets hurt on the job or gets sick and becomes disabled, they can get their gratuity
                        before the five-year mark. Your gratuity is primarily based on how much money you made in the last
                        year and how long you worked for the company.
                    </p>
                    <p class="mb-0 font-weight-bold title">What must happen before someone can get a gratuity?</p>
                    <p class="mb-6 font-weight-light txt-444">For you to be eligible for the gratuity, you must meet the
                        following conditions:
                        <br><br>
                        You should be able to get benefits when you retire.
                        <br><br>
                        You should have stopped your work.
                        <br><br>
                        After working for the same company for five years straight, you need to have given your notice. The
                        gratuity is given to your nominee if you die, or to you if you become unable to work because of an
                        illness or accident
                    </p>
                    <p class="mb-0 font-weight-bold title">What, exactly, is a gratuity calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">The gratuity calculator would be used to figure out the
                        amount of the tip based on the formula for figuring it out.
                        <br><br>
                        As inputs, you have to put in the last wage you got and how long you've been working for the same
                        company. Your last salary included your base pay, the dearness allowance, and any sales commissions.
                        <br><br>
                        Using the calculator, you could quickly find out how much the tip would be. It is easy to use and
                        can be used in a lot of different ways. Under the Gratuity Act, a gratuity can't be more than Rs 20
                        lakh.
                        <br><br>
                        If you worked more than six months in your last job, the number of years you worked will be rounded
                        down to the next whole number. If you have worked for the company for 16 years and 7 months, you
                        would get a 17-year bonus. If not, it is for 16 years if it says 16 years and 4 months.
                        <br><br>
                        If an employer is not covered by the Gratuity Act, the amount of a worker's gratuity would be based
                        on the half-month wage for each year of service.
                    </p>
                    <p class="mb-0 font-weight-bold title">The benefits of the Zebu Gratuity Calculator</p>
                    <p class="mb-6 font-weight-light txt-444">Zebu's Gratuity Calculator is an easy-to-use general tool
                        that asks for the basic income and the number of years of service. If you have worked for the same
                        company for five years or more, it gives you an idea of how much you might get as a bonus.</p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 10,
            gratuity: 0,
        }
    },

    mounted() {
        this.compCalculator();
    },

    methods: {
        atChange() {
            setTimeout(() => {
                this.compCalculator();
            }, 600);
        },
        compCalculator() {
            var experinceyear = parseFloat(this.Principleammount);
            var salary = parseFloat(this.InterestRate);
            // console.log("fdg", experinceyear, salary)
            var gratuity = Math.round(experinceyear * salary * 15 / 26)

            this.gratuity = gratuity.toLocaleString();
        },
    }


}
</script>