<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <v-row no-glutters>
                    <v-col cols="6">
                        <p class="white--text fs-50 font-weight-bold mb-6">Download Center</p>
                        <p class="white--text headline">All required documents and <br> compliance prerequisites in one
                            <br>
                            place.
                        </p>

                    </v-col>
                    <v-col cols="6">
                        <v-container fill-height class="d-flex">
                            <v-card class="elevation-0 mt-auto" width="100%" color="transparent">
                                <p class="mb-2 ml-2 font-weight-thin white--text">What do you need help with?</p>
                                <v-autocomplete @change="goDownload()" item-text="tit" item-value="too" append-icon=""
                                    :items="searchdata" v-model="search" block flat solo background-color="#ffffff"
                                    class="elevation-0 mr-3 rounded-pill caption" label="Search for your document"
                                    hide-details>
                                    <template #prepend-inner>
                                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                                    </template>
                                </v-autocomplete>
                            </v-card>
                        </v-container>

                    </v-col>
                </v-row>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <v-row no-glutters>
                    <v-col cols="12">
                        <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Download Center</p>
                        <p class="white--text fs-18 mb-0">All required documents and compliance prerequisites in one
                            place.
                        </p>

                    </v-col>
                    <v-col cols="12">
                        <v-card class="elevation-0 mt-auto" width="100%" color="transparent">
                            <p class="mb-2 fs-16 font-weight-thin white--text">What do you need help with?</p>
                            <v-text-field block flat solo background-color="#ffffff"
                                class=" elevation-0 rounded-pill mb-3" label="Search for your document" hide-details>
                                <template #prepend-inner>
                                    <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                                </template>
                            </v-text-field>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="py-6 py-md-16 mb-6 pa-6 px-sm-8 px-md-14">
            <p class="text-uppercase font-weight-regular ls-1 lh-38">KYC Forms</p>

            <v-row no-glutters>
                <v-col v-for="(t, c) in Downloaditems" :key="c" cols="12" md="6" xl="4">
                    <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                        :class="c & 1 ? 'ml-md-6 mx-xl-6' : 'mr-md-6 mx-xl-6'">
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18">{{ t.tit }}</v-list-item-title>
                                <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                            </v-list-item-content>
                            <v-btn :href="t.too" target="_blank" rel="noopener noreferrer" text plain
                                color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>


            <p class="text-uppercase font-weight-regular ls-1 lh-38 mt-10">MTF</p>

            <v-row no-glutters>
                <v-col v-for="(t, c) in mtf" :key="c" cols="12" md="6" xl="4">
                    <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                        :class="c & 1 ? 'ml-md-6 mx-xl-6' : 'mr-md-6 mx-xl-6'">
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18">{{ t.tit }}</v-list-item-title>
                                <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                            </v-list-item-content>
                            <v-btn :href="t.too" target="_blank" rel="noopener noreferrer" text plain
                                color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>

            <p class="text-uppercase font-weight-regular ls-1 lh-38 mt-10">Authorized Person (AP) Documents</p>

            <v-row no-glutters>
                <v-col v-for="(t, c) in Authorizeditems" :key="c" cols="12" md="6" xl="4">
                    <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                        :class="c & 1 ? 'ml-md-6 mx-xl-6' : 'mr-md-6 mx-xl-6'">
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18">{{ t.tit }}</v-list-item-title>
                                <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                            </v-list-item-content>
                            <v-btn :href="t.too" target="_blank" rel="noopener noreferrer" text plain
                                color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>

            <p class="text-uppercase font-weight-regular ls-1 lh-38 mt-10">E-Brochure</p>

<v-row no-glutters>
    <v-col v-for="(t, c) in Brochurelist" :key="c" cols="12" md="6" xl="4">
        <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
            :class="c & 1 ? 'ml-md-6 mx-xl-6' : 'mr-md-6 mx-xl-6'">
            <v-list-item class="pr-0">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold fs-18">{{ t.tit }}</v-list-item-title>
                    <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                </v-list-item-content>
                <v-btn :href="t.too" target="_blank" rel="noopener noreferrer" text plain
                    color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
            </v-list-item>
        </v-card>
    </v-col>
</v-row>

        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        Downloaditems: [
            {
                tit: "KYC Form - Individual",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/Trading%20&%20Demat%20KYC.pdf"
            },
            {
                tit: "KYC Form - Non Individual",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/KYC%20Non%20Individualnew.pdf"
            },
            // {
            //     tit: "DP Non Individual Form",
            //     desc: "Financial document given to a participant in a transaction explaining key information in plain language",
            //     too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/DP%20Non%20individual%20form.pdf"
            // },
            // {
            //     tit: "Trading Disclosure Doc ",
            //     desc: "Financial document given to a participant in a transaction explaining key information in plain language",
            //     too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/trading-disclosure-document.pdf"
            // },
            // {
            //     tit: "Commodity Disclosure Doc",
            //     desc: "Financial document given to a participant in a transaction explaining key information in plain language",
            //     too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/COM_Disclosure.pdf"
            // },
            // {
            //     tit: "Demat Disclosure Doc",
            //     desc: "Financial document given to a participant in a transaction explaining key information in plain language",
            //     too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/demat-disclosure-document.pdf"
            // },
            {
                tit: "All Disclosures With RMS Policy",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/All_Disclosures_With_RMS_Policy.pdf"
            },
            {
                tit: "Modification Form",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/Zebu%20modification%20form.pdf"
            },
            // {
            //     tit: "ECN Declaration Form",
            //     desc: "Financial document given to a participant in a transaction explaining key information in plain language",
            //     too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/ECN.pdf"
            // },
            {
                tit: "DDPI Form",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/DDPI.pdf"
            },
            {
                tit: "Segment Enable Form",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/Segment%20Enable%20form.pdf"
            },
            {
                tit: "(Rights & Obligations) in Vernacular Language",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/vernacular.zip"
            },
            {
                tit: "DRF Form",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/DRFform/DRF%20Form.zip"
            },
            {
                tit: "DP Transmission Form",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/DpTransmissionForm.zip"
            },
            {
                tit: "Off-market Beneficiary Addition/Deletion Format",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/Off-market Beneficiary Addition/Deletion Format.pdf"
            },
            {
                tit: "Destatementization Form",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/kyc/Destatementized.pdf"
            },
        ],
        Authorizeditems: [
            {
                tit: "AP Application",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/ap/AP-Application.zip"
            },
            {
                tit: "AP Agreement (NSE)",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/ap/ap-agreement%20nse.pdf"
            },
            {
                tit: "AP Agreement (MCX)",
                desc: "Financial document given to a participant in a transaction explaining key information in plain language",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/ap/MCX%20AP%20agreement.pdf"
            },
        ],
        mtf: [
            {
                tit: "MTF Approved scripts",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/mtf/MTFApprovedscripts.xlsx"
            },
            {
                tit: "MTF Rights and Obligations",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/mtf/MTFRightsandObligations.pdf"
            },
            {
                tit: "MTF Margin Trading Agreement",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/mtf/MARGINTRADINGAGREEMENT.pdf"
            },
            {
                tit: "MTF FAQ",
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/mtf/MTFFAQ.pdf"
            },
        ],

        Brochurelist: [
            {
                tit: "Customer Brochure",
    
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/Brochure.pdf"
            },
            {
                tit: "AP Brochure",
              
                too: "https://zebuetrade.com/asset/static/Downloads/download_docs/AP-Brochure.pdf"
            },
         
        ],

        searchdata: [],
        search: '',
    }),
    created() {
        let merge = [];
        merge = this.Downloaditems.concat(this.Authorizeditems)
        this.searchdata = merge.concat(this.mtf) 
    },
    methods: {
        goDownload() {
            window.open(this.search);
        }
    },

}
</script>