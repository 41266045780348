<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">SSY calculator</p>
                    <p class="title mb-1">Calculate the returns and maturity value for the Sukanya Samriddhi Yojana scheme.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Principal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principalamount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">SSY interest Rate</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <div class="cursor-nd">
                                        <v-text-field block flat solo background-color="#E9E9E9" readonly
                                            class="calc-field elevation-0 rounded-pill" hide-details dense
                                            @keyup="atChange()" type="number" suffix="%" v-model="interestrate"
                                            hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="sinterestrate" ticks="always" tick-size="6"
                                        readonly min="1" max="20" step="1" class="rounded-pill slider-range cursor-nd"
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#E9E9E9'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Start period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="startperiodl"
                                        hide-spin-buttons min="1" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="startperiodl" min="1" max="10000"
                                        class='rounded-pill slider-range' track-color="#EEEEEE" thumb-color="#015FEC"
                                        hide-details color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row no-glutters class="mt-4">
                                <v-col cols="12" md="6">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total investment
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ invest ?
                                                invest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-md-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ interstinG ?
                                                interstinG : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#EEEEEE"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Maturity
                                                year</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ maturtyyer ?
                                                maturtyyer : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Maturity
                                                value</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ finalestimation ?
                                                finalestimation : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                            </v-row>


                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is ssy?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Sukanya Samriddhi Yojana (SSY) is a savings plan for women that was started in 2015 as part of the
                        Beti Bachao, Beti Padhao campaign by the government. With this programme, guardians can open a
                        savings account for their girl child at a commercial bank or India Post branch that has been
                        approved.<br><br>

                        The interest rate for the Sukanya Samriddhi Yojana is 8.5% per year. The amount will be paid back
                        after 21 years. It's important to remember that each person needs to make at least one contribution
                        a year in order to keep the scheme going for 14 years. Between year 1 and year 21, the person can
                        choose not to put money into their SSY account if they want to. But the investments that were
                        already in the account will continue to earn interest at the current rate. So, the final amount is
                        equal to your net contribution plus the interest you earned.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is ssy calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The interest rate for the Sukanya Samriddhi Yojana is 8.5% per year. The amount will be paid back
                        after 21 years. It's important to remember that each person needs to make at least one contribution
                        a year in order to keep the scheme going for 14 years. The person can choose not to put money into
                        their SSY account between the age of 18 and 21 if they want to. It's still possible to keep your
                        money in the account and continue to earn interest at the current rate. As a result, the ultimate
                        sum is determined by multiplying your net contribution by the interest rate you earned.
                    </p>
                    <p class="mb-0 font-weight-bold title">Who all can open Sukanya Samriddhi Account?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        egal guardians of a girl child can open an SSY account if the following conditions are met:<br>
                        The girl must live in India.<br>
                        The girl shouldn't be any older than 10<br>
                        A family with two girl children can open up to two accounts.
                    </p>
                    <p class="mb-0 font-weight-bold title">How do you calculate ssy?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        A = P (1 + r/n) ^ nt<br>
                        Where A is the compound interest, P is the principal amount, r is the rate of interest, n is the
                        number of times the interest is compounded and t is the number of years.
                    </p>
                    <p class="mb-0 font-weight-bold title">Benefits of ssy?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        1. To open a Sukanya Samriddhi Yojana account, you need a small amount of INR 250.<br><br>

                        Before 5th July 2018, the minimum deposit to open an SSY deposit was INR 1,000, but now it's only
                        INR 250. The maximum amount you can put down is INR 1.5 lakh. Do keep in mind that you have to make a deposit
                        until 15 years after the account was opened, or else the account will be put under "Account under
                        default." You can get your account reactivated by paying a fine of INR 50 per year for not making a
                        deposit. The account can be reactivated up to 15 years after it was first opened.<br><br>

                        2. Helps you save money for your daughter's education<br><br>

                        If you are the girl's parent or guardian and she is under 10 years old, you can open an SSY Account
                        for up to two daughters. Now for the huge plus. When the girl turns 18, she can take out 50% of the
                        balance to pay for college. Must show proof of admission.<br><br>

                        3. Triple tax benefits<br><br>

                        If those reasons weren't enough, the scheme also gives you tax breaks that you can't refuse.<br>
                        A. Section 80C of the Income Tax Act allows a deduction for deposits of up to INR 1.5 lakh.<br>
                        B. There are no taxes on the interest earned from the deposit. The interest is added up each
                        year.<br>
                        C. Even the amount you get when the bond matures is not taxed.<br><br>

                        4. Interest rates that are appealing<br>
                        For accounts opened between October 1 and December 31, 2018, the interest rate is 8.6%, which is one
                        of the highest rates for small savings plans.<br><br>

                        5. You only have to put down money for 15 years.<br>
                        After 15 years, you don't have to make any more deposits until the deposit matures, which is 21
                        years after the account was opened. The interest on the deposit will continue to grow.<br><br>

                        6. It is possible to quit early in certain situations<br><br>

                        After 5 years of keeping up with the Deposit Account, if the bank or post office finds that keeping
                        up with the account is putting a financial strain on the girl child because of illness or the death
                        of a guardian, she will be able to get her money out early. Even if a guardian or parent dies, a
                        child can leave the programme early.<br><br>

                        You can also close the account early if the beneficiary is going to get married before he or she
                        turns 18, which is the legal age to get married. (Marriage plans should be made known a month before
                        the wedding and up to three months after the wedding).
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Principalamount: 200000,
            interestrate: 7.6,
            sinterestrate: 7,
            startperiodl: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4),
            maturtyyer: "",
            invest: "",
            interstinG: "",
            finalestimation: "",
        }
    },

    mounted() {
        this.compCalculator();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
        },
        compCalculator() {
            var Principleammount = this.Principalamount;
            // var InterestRate = this.interestrate;
            var startperiod = this.startperiodl;
            var final = 0;
            var maturityyear = startperiod + 21;
            var investedamt = Principleammount * 15;
            for (var i = 1; i <= 21; i++) {
                if (i > 15) {
                    Principleammount = 0;
                }
                final =
                    (parseFloat(final) + parseFloat(Principleammount)) * 0.076 +
                    (parseFloat(final) + parseFloat(Principleammount));
            }
            var intrestgain = final;
            this.maturtyyer = Math.round(maturityyear);
            this.invest = Math.round(investedamt).toLocaleString();
            this.interstinG = Math.round(intrestgain).toLocaleString();
            this.finalestimation = Math.round(final).toLocaleString();
        },
    }


}
</script>