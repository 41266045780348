<template>
  <div>
    <AppBar />
    <div>
      <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
        <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
          <router-view />
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import AppBar from "../Screens/AppBar.vue";
export default {
  data: () => ({}),
  components: {
    AppBar,
  },
};
</script>
<style>
@import "../../assets/style/style.css";
</style>
