<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Fixed-deposit calculator</p>
                    <p class="title mb-1">Evaluate the maturity amount of your fixed deposit based on tenure, interest rate, and amount.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Investment amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="InterestRate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Time period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle
                                                class="mb-2 fs-18 font-weight-regular">Est.intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px=md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is FD?</p>

                    <p class="mb-6 font-weight-light txt-444">Banks and other financial organisations in India provide
                        fixed deposits as a financial product. It is regarded as a secure investment option because of its
                        high yields and flexible tenure choices.
                        <br><br>
                        Fixed Deposit or FD, often known as a term deposit in banking jargon, is a secure investment choice
                        for achieving a healthy financial portfolio balance. FD Calculator is a tool for determining how
                        much and for how long to invest in fixed-income securities.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is FD calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">A fixed deposit calculator is a tool that estimates the
                        maturity amount an investor might expect at the end of a defined term for a certain deposit amount
                        and the appropriate rate of interest.
                        <br><br>
                        A FD calculator is a tool for determining the amount of interest earned on a fixed deposit. The
                        maturity amount is computed using the deposit amount, FD interest rate, and term of the fixed
                        deposit. The maturity amount is what is received at the conclusion of the FD term. It includes all
                        interest generated on the principal (deposit amount).
                    </p>
                    <p class="mb-0 font-weight-bold title">How is FD maturity amount calculated?</p>

                    <p class="mb-6 font-weight-light txt-444">Follow the procedures outlined below to utilise the FD
                        calculator.
                        <br><br>
                        Deposit amount must be entered in the first field (Fixed Deposit Amount)
                        Input the interest rate in the next field (Rate of Interest)
                        Enter the tenure tenure (the period for which you want FD to be active)
                        You may enter FD length in either months or years.
                        <br><br>
                        Select "Calculate" from the menu. The anticipated maturity amount will appear in the table beneath
                        the FD Calculator. Additionally, you can view the entire interest in the column beside the maturity
                        amount.
                    </p>

                    <p class="mb-0 font-weight-bold title">Why should you use Zebu's FD Calculator?</p>

                    <p class="mb-6 font-weight-light txt-444">Investing in an FD, like any other financial decision,
                        requires balancing the individual's budget and financial objectives.
                        <br><br>
                        The purpose of a fixed deposit is to minimise risk and ensure returns. Investing in the market (such
                        as stock mutual funds) carries a substantial risk. Your investment in FDs can sustain losses, if
                        any, to a certain extent. To accomplish this, you must know how much to deposit into an FD account.
                        This can be simply estimated using Zebu's FD Calculator.
                        <br><br>
                        A fixed deposit calculator can also be used to compare the various FD programmes provided by banks
                        and other financial institutions. One can determine which scheme offers the highest returns over the
                        desired time period.
                    </p>

                    <p class="mb-0 font-weight-bold title">What are the benefits of Zebu's FD calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">Here are some of the primary advantages of using the FD
                        Calculator on Zebu:
                        <br><br>
                        No room for error, as it is an automatic calculator.
                        Customers can use the tool several times and compare returns for various combinations of FD rates,
                        tenure, and amount without incurring any costs.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 10,
            TenurePeriodYear: 2,
            freqSelect: 0,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var Principleammounts = parseFloat(this.Principleammount);
            var InterestRates = parseFloat(this.InterestRate) / (100);
            var TenurePeriodYears = parseFloat(this.TenurePeriodYear);
            var first = 1 + InterestRates / 4
            var second = 4 * TenurePeriodYears
            var third = Math.pow(first, second);
            var final = Principleammounts * third
            var emical = Math.round(final);
            var estval = Math.round(emical - Principleammounts);

            this.emireport = Principleammounts.toLocaleString();
            this.emiammount = estval.toLocaleString();
            this.emiinterest = emical.toLocaleString();
            this.doughnuts = [Principleammounts, estval];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>