<template>
    <div class="no-xhide">
        <AppBar />
        <div>
            <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
                <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
                    <v-card height="90vh" class="elevation-0">
                        <v-container fill-height class="text-center pt-0 px-0">
                            <v-card width="100%" color="#F6F6F6" class="rounded-xl mx-auto elevation-0 py-8">
                                <img src="@/assets/404.svg" alt="404 image" class="pt-6 pb-3">
                                <h1 class="font-weight-bold lh-32 mb-4">Sorry, we couldn't find that page :/</h1>
                                <h4 class="font-weight-regular mb-5">Please check the URL or navigate back to our <br>
                                    homepage to
                                    explore our current offerings and
                                    resources
                                </h4>
                                <v-btn color="#2A2A2A" to="/" height="48px"
                                    class="text-none rounded-pill elevation-0 px-8"><span
                                        class="white--text font-weight-medium body-2">Back to homepage</span></v-btn>
                            </v-card>
                        </v-container>
                    </v-card>
                </div>
            </v-container>
            <div class="sec-footer">
                <FooterSet />
            </div>
        </div>
    </div>
</template>

<script>

import AppBar from "../components/AppbarSet.vue";
import FooterSet from '../components/FooterSet.vue';
export default {

    components: {
        FooterSet,
        AppBar
    },
    data: () => ({

    }),
    mounted() {
        var styleElement = document.createElement("style");
        styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 8px; height:6px} *, .v-application, .v-application .body-1, .v-application .body-2, .v-application .caption, .v-application .display-1, .v-application .headline, .v-application .overline, .v-application .subtitle-1, .v-application .subtitle-2, .v-application .title, body, html { font-family: Tenon !important; }"));
        document.getElementsByTagName("head")[0].appendChild(styleElement);
  },
}
</script>