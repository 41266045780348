<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">PPF calculator</p>
                    <p class="title mb-1">Plan your Public Provident Fund savings and calculate maturity value effortlessly.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Yearly investment</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>

                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <div class="cursor-nd">
                                        <v-text-field block flat solo background-color="#E9E9E9" readonly
                                            class="calc-field elevation-0 rounded-pill" hide-details dense
                                            @keyup="atChange()" type="number" suffix="%" v-model="interestrate" hide-spin-buttons
                                            min="1" max="20" step="1"></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="sinterestrate" ticks="always" tick-size="6" readonly
                                        min="1" max="20" step="1" class="rounded-pill slider-range cursor-nd"
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#E9E9E9'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle
                                                class="mb-2 fs-18 font-weight-regular">Est.intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is a calculator for PPF?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        If you want to invest in PPF but don't know how much to invest or how much return you'll get, you
                        can use our calculator.
                        <br><br>
                        Once you decide how much you can invest every month, the calculator uses 15 years and the current
                        interest rate to figure out how much you will make back.
                    </p>

                    <p class="mb-0 font-weight-bold title">How Do I Use the PPF Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">The interface of our PPF calculator is easy to understand
                        and use. But if you have never used an online calculator before, here is a simple step-by-step guide
                        on how to use this free one:
                        <br><br>
                        Step 1: There is a drop-down menu under the "Frequency of Investment" field. Click on the drop-down
                        menu to see different options. Choose from the drop-down menu according on how often you can put
                        into PPF each year.
                        <br><br>
                        Step 2: Under "Yearly Deposit Amount," put the amount you want to put into your PPF account over the
                        course of a financial year. Keep in mind that the major of you can put into your PPF account each year
                        is Rs.1.5 lakh.
                        <br><br>
                        Step 3: By default, you can see the current interest rate for your own information.
                        <br><br>
                        Step 4: Choose the number of years you want to keep your money in the PPF account. The minimum
                        amount of time to invest is 15 years, so this is the choice that comes up by default.
                        <br><br>
                        Step 5: Based on the numbers you put in and the current interest rate, our calculator automatically
                        figures out how much your PPF account will be worth when it matures.
                    </p>
                    <p class="mb-0 font-weight-bold title">What Can the PPF Calculator Do for You?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        When you are planning your investments, it can be very helpful to use a PPF calculator to estimate
                        the returns because:
                        <br><br>
                        Your questions about how the account works will be answered by the calculator.
                        <br><br>
                        You can get a clear idea of how much you can expect to get back if you invest a certain amount.
                        <br><br>
                        You can use the calculator as many times as you need to figure out how much you need to invest to
                        get the returns you want.
                        <br><br>
                        Since this is done automatically, you don't have to do any calculations by hand and can avoid making
                        mistakes.
                        <br><br>
                        When you're planning your taxes, you can use the calculator to help you plan your investments
                        better.
                        <br><br>
                        Since you can extend your PPF account beyond the lock-in period, you may be able to figure out how
                        long you have until retirement and how much money you can make in that time.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is PPF and what are its benefits?</p>
                    <p class="mb-6 font-weight-light txt-444">Public Provident Fund (PPF) was started in India in 1968
                        with the goal of getting people to save small amounts of money and invest them in something that
                        would give them a return. It is still a favourite way for many people to save money because the
                        returns are not taxed. It's a savings-cum-tax investment vehicle that helps create a retirement
                        corpus while reducing annual taxes. So, anyone looking for a safe way to invest money, save on
                        taxes, and make sure they'll get their money back should open a PPF account.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 20000,
            interestrate: 7.1,
            sinterestrate: 7,

            tenureperiod: 15,
            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var amt = this.Monthlyinvestment;
            var rate = this.interestrate / 100;
            var year = this.tenureperiod;

            // var freq=1;

            var first = 1 + rate;
            var second = Math.pow(first, year);
            var third = second - 1;
            var fourth = third / rate;
            var f = amt * fourth;
            var fi = f * rate;
            var maturity = fi + f;
            var emical = Math.round(maturity);
            var totalinvested = amt * year;
            var totalintrest = Math.round(maturity - totalinvested);

            this.emireport = totalinvested.toLocaleString();
            this.emiammount = totalintrest.toLocaleString();
            this.emiinterest = emical.toLocaleString();
            this.doughnuts = [totalinvested, totalintrest];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>