<template>
    <div class="no-xhide">
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10"
            transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true"
            :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ errMsg }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>

        </v-snackbar>
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <!-- <v-row no-glutters>
                    <v-col cols="6"> -->
                <p class="white--text fs-50 font-weight-bold mb-6">Whistle Blower </p>
                <p class="white--text headline">
                    We uphold integrity, transparency, and ethical conduct<br>
                    in all our operations. Our Whistle Blower channel ensures<br>
                    a safe, confidential way to report misconduct or violations.
                </p>
                <p>
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <v-row no-glutters>
                    <v-col cols="12">
                        <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Whistle Blower</p>
                        <p class="white--text fs-18 mb-0">We uphold integrity, transparency, and ethical conduct in all
                            operations. Our Whistle Blower channel ensures a safe, confidential way to report any
                            misconduct or policy violations.
                        </p>

                    </v-col>

                </v-row>
            </v-card>
        </div>

        <div class="py-md-0 mb-md-6 px-md-16">
            <div class="pt-10 mb-8 mb-md-4 px-6 px-sm-8 px-md-16 mx-md-0">
                <div>
                    <p class="secondary-infont fs-32 mb-1 lh-48">Assured Confidentiality</p>
                    <p id="jobs" class="font-weight-regular fs-17 txt-444">All reports
                        made through this platform will be handled with strict confidentiality. Identities of whistle
                        blowers will be protected, and no retaliatory action will be taken against individuals who raise
                        concerns in good faith.
                    </p>


                    <v-card width="60%"
                        class="price-card-sty1 d-none d-md-block mt-8 elevation-0 rounded-xl pa-6 sec-two-sty2 rounded-xl ">

                        <p class="mb-2 fs-15">Escalation Level <span class="fs-17  red--text ml-1">*</span></p>

                        <v-select class="mt-1 rounded-pill" hide-details background-color="#fff" min="0"
                            v-model="segment_select" :items="escalationLevels" item-text="label" flat solo>
                            <template v-slot:label>
                                <span class="caption small-label">Select Your Escalation Level</span>
                            </template>
                            <!-- <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <div >
                                        <v-alert dense border="top" colored-border
                                            :color="item.color" elevation="0" class="mt-0" rounded="lg">
                                            <span class="text-uppercase fs-14 font-weight-bold txt-666666">
                                                {{ item.label }}
                                            </span>
                                        </v-alert>
                                    </div>
                                </v-list-item>
                            </template> -->
                        </v-select>
                        <p class=" mt-3 mb-2 fs-15">Detail Your Issue <span class="fs-17  mb-0 red--text ml-1">*</span>
                        </p>

                        <v-textarea shaped auto-grow v-model="ticketdes" hide-details class="mt-0 pt-0"
                            :style="{ backgroundColor: '#fff' }" rows="3" flat solo>
                            <template v-slot:label>
                                <span class="caption rounded-pill small-label">Share the Details of Your Issue</span>
                            </template>
                        </v-textarea>

                        <p class="mt-5 fs-15">Add a Screenshot for Clarity </p>

                        <v-file-input block flat solo background-color="#fff" hide-details id="resumeCVCareersForm"
                            v-model="files" class="elevation-0 rounded-pill caption" label="No file chosen"
                            accept="application/pdf" @input="$v.files.$touch()" prepend-inner-icon="mdi-paperclip"
                            prepend-icon=""></v-file-input>



                        <v-btn large color="#2A2A2A" type="submit" :loading="carrloader"
                            :disabled="!segment_select || !ticketdes " @click="raiseticket()"
                            class="elevation-0 mt-8 rounded-pill text-none brd-2 " block>
                            <span class="white--text">Raise Your Concern</span>
                        </v-btn>

                    </v-card>

                    <v-card width="100%"
                        class="price-card-sty1 d-md-none mt-8 elevation-0 rounded-xl pa-6 sec-two-sty2 rounded-xl ">

                        <p class="mb-2 fs-17">Escalation Level <span class="fs-17  red--text ml-1">*</span></p>

                        <v-select class="mt-1" hide-details background-color="#F1F3F8" min="0" v-model="segment_select"
                            :items="escalationLevels" item-text="label" flat solo>
                            <template v-slot:label>
                                <span class="caption small-label">Select Your Escalation Level</span>
                            </template>
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <div style="width: 100% !important;">
                                        <v-alert style="width: 100% !important;" dense border="top" colored-border
                                            :color="item.color" elevation="0" class="mt-0" rounded="lg">
                                            <span class="text-uppercase fs-14 font-weight-bold txt-666666">
                                                {{ item.label }}
                                            </span>
                                        </v-alert>
                                    </div>
                                </v-list-item>
                            </template>
                        </v-select>
                        <p class=" mt-3 mb-2 fs-17">Detail Your Issue <span class="fs-17  mb-0 red--text ml-1">*</span>
                        </p>

                        <v-textarea v-model="ticketdes" hide-details class="mt-0 pt-0" rows="3"
                            background-color="#F1F3F8" flat solo>
                            <template v-slot:label>
                                <span class="caption small-label">Share the Details of Your Issue</span>
                            </template>
                        </v-textarea>
                        <p class="mt-3 fs-17">Add a Screenshot for Clarity </p>

                        <v-file-input block flat solo background-color="#fff" hide-details id="resumeCVCareersForm"
                            v-model="files" class="elevation-0 rounded-pill caption" accept="application/pdf"
                            @input="$v.files.$touch()" prepend-inner-icon="mdi-paperclip" prepend-icon="">
                            <template v-slot:label>
                                <span class="caption small-label">No file chosen</span>
                            </template>
                        </v-file-input>



                        <v-btn @click="raiseticket()" large color="#2A2A2A" type="submit" :loading="carrloader"
                            :disabled="!segment_select || !ticketdes "
                            class="elevation-0 mt-8 rounded-pill text-none brd-2 " block>
                            <span class="white--text">Raise Your Concern</span>
                        </v-btn>

                    </v-card>


                </div>
            </div>
        </div>




    </div>
</template>

<script>
export default {

    data: () => ({
        segment_select: "",
        escalationLevels: ["Level 1","Level 2"],
        files: "",
        ticketdes: "",
        carrloader: false,
        snackbar: false,
        padless: false,
        snackbarclr: "#000",
        errMsg: ""
    }),

    methods: {
        raiseticket() {
            this.carrloader = true
            const axios = require('axios');
            const FormData = require('form-data');
            // const fs = require('fs');
            let data = new FormData();
            data.append('level', this.segment_select == "Level 1" ? "LEVEL1" : "LEVEL2");
            data.append('issue', this.ticketdes);
            data.append('files', this.files);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://192.168.5.175:5000/mail_trigger',
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.carrloader = false
                    console.log("jsxbjasbasb", (typeof response.data));
                    if (response.data.Stat == "Ok") {
                    console.log("ifff", (typeof response.data));

                        axiosThis.snackbar = true
                        axiosThis.errMsg = "Thank you, Our expert will get in touch with you shortly."
                        axiosThis.snackbarclr = "green"
                    }
                    else {
                    console.log("elseee", (typeof response.data));

                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }
                    axiosThis.clearfun()
                    // axiosThis.this.segment_select = ""
                    // axiosThis.this.ticketdes = ""
                    // axiosThis.files = ""
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.carrloader = false
                    axiosThis.snackbar = true
                    axiosThis.errMsg = error
                    axiosThis.snackbarclr = "error"
                });

        },
        clearfun(){
            this.segment_select = ""
            this.ticketdes = ""
            this.files = ""
        }
    },

}
</script>