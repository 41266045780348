<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Home loan calculator</p>
                    <p class="title mb-1">Calculate your home loan EMIs and assess repayment schedules with this easy-to-use calculator.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Loan amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principleammounts" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="InterestRate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>

                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly
                                                EMI</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalreport ?
                                                emicalreport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Loan
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalemiammount ?
                                                emicalemiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Payment</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emitotal ?
                                                emitotal : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is a home loan calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">The Zebu home loan calculator makes it easy to figure out
                        your Home Loan EMI. The Zebu home loan EMI calculator can help you decide whether or not to buy a
                        new home. The EMI calculator can help you figure out how you will pay off your mortgage. Use our
                        home loan EMI calculator, which is easy to use, to figure out how much you will have to pay each
                        month for your mortgage.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is the EMI on a home loan?</p>
                    <p class="mb-6 font-weight-light txt-444">EMI stands for "equal monthly instalment." It means paying
                        down both the original balance of your mortgage loan and any interest that has built up. A longer
                        loan term (up to 30 years) is good if you want to lower your EMI.
                        <br><br>
                        With the help of the Zebu EMI Calculator, it's easy to figure out how much your loan payment will
                        be.
                    </p>
                    <p class="mb-0 font-weight-bold title"> How can EMI calculations help with the process of buying a
                        house?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        With the help of Zebu's home loan EMI calculator, it's easier to make an informed decision about the
                        monthly payment for the housing loan. This is because the calculator gives a clear picture of the
                        amount that must be paid toward the EMIs. This helps figure out how much of a loan you can get, how
                        much the property costs, and whether or not you need to put money down yourself. Because of this,
                        you need to know what the EMI is in order to see if you can get a house loan and to plan your
                        home-buying process better.
                    </p>
                    <p class="mb-0 font-weight-bold title">How to Use the Zebu Home Loan EMI Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">To figure out your EMI, you just need to put in the
                        following details:
                        <br><br>
                        Type in the amount of money you want to borrow as the loan amount. Loan Term (in Years): Type in the
                        length of time you want to borrow for your home loan. Having a longer term makes it easier to
                        qualify. Rate of Interest (% P.A.): the rate of interest.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Principleammounts: 200000,
            InterestRate: 8,
            TenurePeriodYear: 2,
            emicalreport: 0,
            emicalemiammount: 0,
            emiintrestdisplay: 0,
            emitotal: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.carCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.carCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.carCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        carCalculator() {

            var Principleammount = this.Principleammounts;
            this.emicalemiammount = parseInt(Principleammount)
            var InterestRates = this.InterestRate / (100 * 12);

            var TenurePeriodYears = this.TenurePeriodYear * 12;
            // console.log("count", Principleammount, InterestRates, TenurePeriodYears)

            if ((Principleammount > 0 && InterestRates > 0 && (TenurePeriodYears > 0)) && (Principleammount <= Math.pow(10, 9) && InterestRates <= 50 / (100 * 12) && (TenurePeriodYears <= 50 * 12))) {
                var emical = Math.round(([(parseFloat(Principleammount) * parseFloat(InterestRates) * Math.pow(1 + parseFloat(InterestRates), parseFloat(TenurePeriodYears)))] / [(Math.pow((1 + parseFloat(InterestRates)), (parseFloat(TenurePeriodYears))) - 1)]))
                this.emicalreport = emical.toLocaleString()
                // console.log("emical", emical)
                var emiintrest = (parseFloat(emical) * parseFloat(TenurePeriodYears)) - parseFloat(Principleammount);
                this.emiintrestdisplay = parseInt(emiintrest)
                var emitotal = parseInt(Principleammount) + parseInt(emiintrest);

                this.emiinterest = emiintrest.toLocaleString()
                this.emitotal = emitotal.toLocaleString()
            }
            this.doughnuts = [Principleammount, emiintrest];

        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>