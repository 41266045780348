<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl text-center d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Details of Bank Account(s)</p>
                <p class="white--text headline">All required documents and compliance <br> prerequisites in one place.
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 text-center d-md-none">
                <p class="white--text fs-38 font-weight-bold mb-6 lh-38">Details of Bank Account(s)</p>
                <p class="white--text fs-18">All required documents and compliance <br> prerequisites in one place.
                </p>
            </v-card>
        </div>
        <div class="pa-md-16 pa-6 px-sm-8 mb-6 mx-md-16">
            <div class="px-md-16">
                <div>
                    <p class="font-weight-bold title mb-2 mb-md-3">Details of Bank Account(s)</p>
                    <p class="mt-1 mb-0 font-weight-light lh-32">“Investors are requested to note that Stock broker Zebu
                        Share
                        and Wealth
                        Managements Private Limited is permitted to receive/pay money from/to investor through designated
                        bank
                        accounts only named as client bank accounts. Stock broker Zebu Share and Wealth Managements Private
                        Limited
                        is also required to disclose these client bank accounts to Stock Exchange. Hence, you are requested
                        to
                        use
                        following client bank accounts only for the purpose of dealings in your trading account with us. The
                        details
                        of these client bank accounts are also displayed by Stock Exchanges on their website under “Know/
                        Locate
                        your Stock Broker” or “Membership → Notice Board → Member & AP Details”.</p>
                </div>

                <v-row class="mt-4 mt-md-8">
                    <v-col cols="12" sm="6" v-for="(a, b) in bankaccounts" :key="b" class="pb-0 pb-md-3">
                        <v-card data-aos="flip-up" width="100%" class="box-s1 py-4 px-6 sec-two-sty1 rounded-xl mb-4"
                            :class="b & 1 ? 'ml-md-2' : 'mr-md-2'">
                            <p class="font-weight-bold title">{{ a.Bankname }}</p>
                            <p class="mt-4 font-weight-bold mb-0">{{ a.AccountnumText }}</p>
                            <p class="font-weight-light">{{ a.AccountNumber }}</p>
                            <p class="mt-4 font-weight-bold mb-0">{{ a.IFSCCodeText }}</p>
                            <p class="font-weight-light mb-0">{{ a.IFSCCodeNumber }}</p>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        bankaccounts: [
            {
                Bankname: "HDFC Bank Limited",
                AccountnumText: "Account number",
                AccountNumber: "02320340000361",
                IFSCCodeText: "IFSC code",
                IFSCCodeNumber: "HDFC0000232"
            },
            {
                Bankname: "ICICI Bank Limited",
                AccountnumText: "Account number",
                AccountNumber: "000905033988",
                IFSCCodeText: "IFSC code",
                IFSCCodeNumber: "ICIC0000009"
            },
            // {
            //     Bankname: "AXIS Bank Limited",
            //     AccountnumText: "Account number",
            //     AccountNumber: "922020058095051",
            //     IFSCCodeText: "IFSC code",
            //     IFSCCodeNumber: "UTIB0000006"
            // },
            // {
            //     Bankname: "IDFC first Bank Limited",
            //     AccountnumText: "Account number",
            //     AccountNumber: "922020058095051",
            //     IFSCCodeText: "IFSC code",
            //     IFSCCodeNumber: "IDFC0000006"
            // },
        ],
    }),
}
</script>