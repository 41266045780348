<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pos-rlt">
      <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
        <p class="white--text fs-50 font-weight-bold mb-6">Refer your friends to Zebu</p>
        <p class="white--text headline">
          Get 20% of brokerage fees for trades made <br />
          by your friends! in every
        </p>

        <div class="pos-abs ref-img text-right">
          <img alt="main-bg.png" src="@/assets/refer/main-bg.svg" width="90%" />
        </div>
      </v-card>
      <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
        <p class="white--text fs-38 font-weight-bold mb-6">Refer your friends to Zebu</p>
        <p class="white--text fs-18">Get 20% of brokerage fees for trades made by your friends! in every</p>
      </v-card>
    </div>
    <div class="py-md-10 mt-6 px-4 px-sm-6 px-md-16">
      <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
        <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
      </v-snackbar>
      <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10" transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
        <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
      </v-snackbar>
      <div class="pa-2 pa-md-4">
        <v-row>
          <v-col cols="12" md="6" class="pr-md-4 mb-2 mb-md-0">
            <v-card width="100%" class="ref-card-m rounded-xl pl-10 pt-10 pb-2 d-none d-md-block">
              <p class="mb-0">
                <span class="secondary-infont sec-eight-listtitle">20</span>
                <sub class="fs-28 secondary-fn font-weight-thin">%</sub>
              </p>
              <p class="secondary-infont fs-28 mb-10">Of brokerage</p>
              <p class="txt-444 font-weight-thin fs-28">in 3 easy steps <span class="font-weight-black">→</span></p>
            </v-card>
            <v-card width="100%" class="ref-card-m rounded-xl pl-6 pl-sm-8 pt-6 pt-md-10 pb-2 d-md-none">
              <p class="mb-0">
                <span class="secondary-fn font-weight-bold fs-64">20</span>
                <span class="secondary-infont fs-28"> % Of brokerage</span>
              </p>
              <p class="txt-444 font-weight-thin title">in 3 easy steps <span class="font-weight-black">↓</span></p>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="pl-md-4">
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl px-6 px-sm-8 py-md-6 pt-6 mb-8">
              <div class="d-md-inline-flex">
                <p class="font-weight-bold lh-28 fs-32 mb-md-0">1.</p>
                <div>
                  <p class="font-weight-bold lh-28 fs-24 ml-md-2"><span>Create your referral link, to easy share</span></p>
                  <p class="font-weight-light txt-444 ml-md-2 mb-6">Enter your client ID below to generate the refferal link. You can get your client ID from your dashboard.</p>

                  <v-text-field @keyup="btnenable == false ? nullClients() : ''" id="content" block flat solo background-color="#ffffff" v-model="clientid" oninput="this.value = this.value.toUpperCase()" class="ref-field elevation-0 rounded-pill caption" label="Enter client code">
                    <template #prepend-inner>
                      <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2" alt="search-icon" />
                    </template>
                    <template #append>
                      <v-btn v-if="btnenable == true" :loading="btnload" class="cursor-p" icon plain @click="clientid.length > 0 ? getrefferalUrl() : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M3.33337 10H16.6667M16.6667 10L11.6667 5M16.6667 10L11.6667 15" stroke="#2A2A2A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </v-btn>

                      <div v-if="btnenable == false" class="cursor-p" @click="copylink()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clip-path="url(#clip0_251_10223)">
                            <path
                              d="M3.33333 9.99992C2.71207 9.99992 2.40145 9.99992 2.15642 9.89842C1.82971 9.7631 1.57015 9.50353 1.43482 9.17683C1.33333 8.9318 1.33333 8.62117 1.33333 7.99992V3.46659C1.33333 2.71985 1.33333 2.34648 1.47865 2.06126C1.60648 1.81038 1.81046 1.60641 2.06134 1.47858C2.34656 1.33325 2.71992 1.33325 3.46666 1.33325H7.99999C8.62125 1.33325 8.93188 1.33325 9.17691 1.43475C9.50361 1.57007 9.76318 1.82964 9.8985 2.15634C9.99999 2.40137 9.99999 2.712 9.99999 3.33325M8.13333 14.6666H12.5333C13.2801 14.6666 13.6534 14.6666 13.9386 14.5213C14.1895 14.3934 14.3935 14.1895 14.5213 13.9386C14.6667 13.6534 14.6667 13.28 14.6667 12.5333V8.13325C14.6667 7.38651 14.6667 7.01315 14.5213 6.72793C14.3935 6.47705 14.1895 6.27307 13.9386 6.14524C13.6534 5.99992 13.2801 5.99992 12.5333 5.99992H8.13333C7.38659 5.99992 7.01322 5.99992 6.72801 6.14524C6.47712 6.27307 6.27315 6.47705 6.14532 6.72793C5.99999 7.01315 5.99999 7.38651 5.99999 8.13325V12.5333C5.99999 13.28 5.99999 13.6534 6.14532 13.9386C6.27315 14.1895 6.47712 14.3934 6.72801 14.5213C7.01322 14.6666 7.38659 14.6666 8.13333 14.6666Z"
                              stroke="#2A2A2A"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_251_10223">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-card>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl px-6 px-sm-8 py-md-6 pt-6 mb-8">
              <div class="d-md-inline-flex">
                <p class="font-weight-bold lh-28 fs-32 mb-md-0">2.</p>
                <div>
                  <p class="font-weight-bold lh-28 fs-24 ml-md-2"><span>Refer your family and friends</span></p>
                  <p class="font-weight-light txt-444 ml-md-2 mb-6">Get discount on brokerages by referring them with your referral link.</p>
                  <div class="d-none d-md-block">
                    <v-btn :disabled="btnenable" :href="sharetextweb + clientid" target="_blank" rel="noopener noreferrer" large color="#2A2A2A" class="elevation-0 rounded-pill mb-3 ml-2 text-none brd-2">
                      <div class="mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_1307_10879)">
                            <path
                              d="M17 2.875C15.125 1 12.75 0 10 0C4.625 0 0.125 4.5 0.125 9.875C0.125 11.625 0.625 13.375 1.5 14.875L0 20L5.25 18.625C6.75 19.375 8.375 19.875 10 19.875C15.5 19.875 19.875 15.375 19.875 10C20 7.25 18.875 4.75 17 2.875ZM14.875 13.5C14.625 14.125 13.625 14.625 13.25 14.625C12.875 14.75 11.625 14.625 10.25 14C7.75 12.875 6.125 10.375 6 10.25C5.875 10.125 5 8.875 5 7.75C5 6.625 5.625 5.875 5.875 5.625C6.375 5.125 7.375 5.25 7.625 5.75C7.75 6.25 8.25 7.5 8.375 7.625C8.5 7.75 8.5 8.25 8.125 8.5C8 8.5 7.375 9 7.625 9.375C7.75 9.625 8.25 10.375 9 11.125C10 12 10.75 12.25 11 12.375C11.25 12.5 11.375 12.5 11.5 12.25C11.625 12.125 12.125 11.5 12.25 11.25C12.375 11 12.625 11 12.75 11.125C12.875 11.25 14.25 11.75 14.5 11.875C14.75 12.125 15 12.125 15 12.25C15.125 12.375 15.125 12.875 14.875 13.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1307_10879">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <span class="white--text">Refer on whatsapp</span>
                    </v-btn>

                    <v-btn :disabled="btnenable" @click="shareViaWebShare" large color="#2A2A2A" class="elevation-0 rounded-pill ml-2 text-none brd-2" outlined>
                      <span>More ways to share</span>
                    </v-btn>
                  </div>
                  <div class="d-md-none">
                    <v-btn :disabled="btnenable" block :href="sharetextweb + clientid" target="_blank" rel="noopener noreferrer" large color="#2A2A2A" class="elevation-0 rounded-pill mb-3 text-none brd-2">
                      <div class="mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_1307_10879)">
                            <path
                              d="M17 2.875C15.125 1 12.75 0 10 0C4.625 0 0.125 4.5 0.125 9.875C0.125 11.625 0.625 13.375 1.5 14.875L0 20L5.25 18.625C6.75 19.375 8.375 19.875 10 19.875C15.5 19.875 19.875 15.375 19.875 10C20 7.25 18.875 4.75 17 2.875ZM14.875 13.5C14.625 14.125 13.625 14.625 13.25 14.625C12.875 14.75 11.625 14.625 10.25 14C7.75 12.875 6.125 10.375 6 10.25C5.875 10.125 5 8.875 5 7.75C5 6.625 5.625 5.875 5.875 5.625C6.375 5.125 7.375 5.25 7.625 5.75C7.75 6.25 8.25 7.5 8.375 7.625C8.5 7.75 8.5 8.25 8.125 8.5C8 8.5 7.375 9 7.625 9.375C7.75 9.625 8.25 10.375 9 11.125C10 12 10.75 12.25 11 12.375C11.25 12.5 11.375 12.5 11.5 12.25C11.625 12.125 12.125 11.5 12.25 11.25C12.375 11 12.625 11 12.75 11.125C12.875 11.25 14.25 11.75 14.5 11.875C14.75 12.125 15 12.125 15 12.25C15.125 12.375 15.125 12.875 14.875 13.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1307_10879">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <span class="white--text">Refer on whatsapp</span>
                    </v-btn>

                    <v-btn :disabled="btnenable" @click="shareViaWebShare" block large color="#2A2A2A" class="elevation-0 rounded-pill mb-6 text-none brd-2" outlined>
                      <span>More ways to share</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl px-6 px-sm-8 py-6 mb-8">
              <div class="d-md-inline-flex">
                <p class="font-weight-bold lh-28 fs-32 mb-md-0">3.</p>
                <div>
                  <div class="ml-2 mb-2 d-none d-md-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                      <g clip-path="url(#clip0_1307_10889)">
                        <path
                          d="M66.2306 69.4846C65.9253 69.7888 65.5554 70.0184 65.1239 70.1756L64.6886 70.3064C64.6886 70.3064 5.98493 93.9919 1.42034 89.4107C-3.14425 84.8294 20.7548 26.2124 20.7548 26.2124L20.8872 25.7776C21.046 25.3466 21.277 24.9776 21.5823 24.6734C25.234 21.0349 38.1901 28.116 50.5192 40.4901C62.8483 52.8642 69.8823 65.8463 66.2306 69.4846Z"
                          fill="url(#paint0_linear_1307_10889)"
                        />
                        <path
                          d="M62.5788 71.1488C57.0746 73.3284 51.5476 75.4499 45.9988 77.5131C41.5313 79.1738 36.645 80.9428 31.7929 82.6099C27.5529 84.067 23.3382 85.4491 19.4546 86.6137C16.7864 84.3713 14.1348 81.9391 11.5369 79.332C8.93912 76.7248 6.51689 74.0641 4.28418 71.3878C5.46283 67.5086 6.8603 63.2987 8.33294 59.0641C10.0177 54.2181 11.8045 49.3383 13.4815 44.877C15.565 39.3357 17.7066 33.8165 19.9061 28.3203C27.7827 32.5916 36.1994 38.9084 44.1247 46.8626C52.0502 54.817 58.3362 63.2567 62.5788 71.1488Z"
                          fill="url(#paint1_linear_1307_10889)"
                        />
                        <path
                          d="M66.2299 69.4841C65.9253 69.7894 65.5546 70.0177 65.1233 70.176L64.6884 70.3057C64.6884 70.3057 63.9294 70.6119 62.578 71.1478C57.0739 73.3274 51.5469 75.4489 45.9981 77.5121C41.5306 79.1728 36.6443 80.9418 31.7922 82.6089C27.5749 79.4282 23.3479 75.7537 19.2522 71.643C15.1565 67.5323 11.4976 63.2919 8.33203 59.0631C10.0168 54.2171 11.8036 49.3373 13.4806 44.876C15.564 39.3347 17.7057 33.8155 19.9051 28.3193C20.446 26.9698 20.755 26.2121 20.755 26.2121L20.8863 25.7776C21.0464 25.3468 21.276 24.977 21.5823 24.6734C25.2342 21.0348 38.1891 28.1164 50.5191 40.4894C62.8475 52.8648 69.8818 65.8454 66.2299 69.4841Z"
                          fill="url(#paint2_linear_1307_10889)"
                        />
                        <path
                          d="M66.2306 69.4839C65.926 69.7892 65.5553 70.0176 65.124 70.1759L64.6892 70.3056C64.6892 70.3056 63.9302 70.6117 62.5787 71.1477C57.0746 73.3272 51.5476 75.4487 45.9988 77.5119C40.0533 73.5964 33.963 68.6344 28.1391 62.7891C22.3152 56.9439 17.3753 50.8356 13.4814 44.876C15.5649 39.3347 17.7066 33.8155 19.906 28.3193C20.4469 26.9698 20.7559 26.2121 20.7559 26.2121L20.8872 25.7776C21.0473 25.3468 21.2769 24.977 21.5832 24.6734C25.2351 21.0348 38.19 28.1164 50.52 40.4894C62.8482 52.8646 69.8825 65.8453 66.2306 69.4839Z"
                          fill="url(#paint3_linear_1307_10889)"
                        />
                        <path d="M66.2305 69.4849C62.5781 73.1241 49.6222 66.0431 37.2931 53.6688C24.9639 41.2945 17.9298 28.3131 21.5822 24.6738C25.2346 21.0344 38.1906 28.1156 50.5197 40.4899C62.8488 52.8642 69.8829 65.8458 66.2305 69.4849Z" fill="url(#paint4_linear_1307_10889)" />
                        <path d="M63.9542 66.1508C61.256 68.8393 50.328 62.2463 39.5462 51.4252C28.7644 40.604 22.2113 29.6522 24.9095 26.9637C27.6078 24.2752 38.5357 30.8681 49.3175 41.6893C60.0994 52.5105 66.6526 63.4622 63.9542 66.1508Z" fill="url(#paint5_linear_1307_10889)" />
                        <path
                          d="M66.2307 69.4838C69.8825 65.8453 62.8487 52.8636 50.5194 40.4895C43.2899 33.2337 35.8459 27.7993 30.1875 25.2773C30.4131 25.6928 30.6612 26.0958 30.9304 26.4844C31.3781 27.1303 31.9023 27.719 32.4924 28.2498L35.6231 31.4023C35.3052 31.5468 34.9811 31.6774 34.6519 31.7939C34.4105 31.879 34.2081 32.0484 34.0819 32.271C33.9556 32.4935 33.9141 32.7542 33.9649 33.005L35.0727 38.5081C35.1188 38.7372 35.2225 38.9508 35.3739 39.1289C35.5254 39.3069 35.7197 39.4434 35.9385 39.5256L65.9957 69.6923C66.0777 69.6274 66.1563 69.5581 66.2307 69.4838Z"
                          fill="url(#paint6_linear_1307_10889)"
                        />
                        <path
                          d="M46.2448 52.3884L49.3193 56.0637L63.6411 70.4378C64.7358 70.4069 65.6153 70.0993 66.2301 69.4866C69.4218 66.3064 64.4529 55.9924 54.9213 45.1836C51.9193 46.4367 49.0907 48.0702 46.5049 50.0441C45.7662 50.6078 45.6485 51.6756 46.2448 52.3884Z"
                          fill="url(#paint7_linear_1307_10889)"
                        />
                        <path
                          d="M49.3193 56.0623L46.2448 52.3869C45.6485 51.6742 45.7662 50.6065 46.5048 50.0425C56.0329 42.768 68.4266 40.4053 80.0015 43.7344C82.9018 44.567 85.6927 45.7417 88.3152 47.234C89.1229 47.694 89.3818 48.7372 88.8856 49.5232L86.3278 53.5751C85.8641 54.3098 84.9074 54.5498 84.1497 54.1252C82.1347 52.9977 79.9963 52.1067 77.7769 51.4698C68.7249 48.8663 59.0366 50.6829 51.5509 56.3176C50.8571 56.8398 49.8764 56.7283 49.3193 56.0623Z"
                          fill="url(#paint8_linear_1307_10889)"
                        />
                        <path
                          d="M22.7523 17.9055L19.9607 15.0331C18.6954 13.7311 18.3144 11.6353 19.0533 10.0418C19.9111 8.19147 21.9698 7.64918 23.6279 8.83665C25.0561 9.85947 25.757 11.8709 25.2973 13.6273L24.2833 17.5022C24.1042 18.1869 23.2457 18.4132 22.7523 17.9055Z"
                          fill="url(#paint9_linear_1307_10889)"
                        />
                        <path
                          d="M17.8145 21.0265L13.8506 20.4505C12.0539 20.1895 10.4745 18.7601 10.0891 17.0464C9.64157 15.0565 10.9432 13.372 12.9815 13.303C14.737 13.2435 16.5186 14.4108 17.2247 16.0836L18.7824 19.7738C19.0579 20.4258 18.5149 21.1284 17.8145 21.0265Z"
                          fill="url(#paint10_linear_1307_10889)"
                        />
                        <path
                          d="M70.8858 67.1008L74.8749 66.7398C76.683 66.5762 78.5513 67.5992 79.3245 69.1765C80.2222 71.0077 79.3478 72.9487 77.3813 73.4898C75.6876 73.9557 73.6836 73.2343 72.608 71.7715L70.2353 68.5444C69.8162 67.974 70.1808 67.1645 70.8858 67.1008Z"
                          fill="url(#paint11_linear_1307_10889)"
                        />
                        <path
                          d="M71.571 61.301L74.5136 58.5835C75.8474 57.3518 77.9522 57.0244 79.5265 57.8035C81.3544 58.7083 81.8441 60.7801 80.6146 62.4075C79.5558 63.8092 77.5271 64.4586 75.783 63.9544L71.9351 62.842C71.2553 62.6454 71.0511 61.7812 71.571 61.301Z"
                          fill="url(#paint12_linear_1307_10889)"
                        />
                        <path
                          d="M36.8393 39.5512C36.0587 39.8028 35.2339 39.314 35.0721 38.51L33.9642 33.0069C33.9134 32.7561 33.955 32.4955 34.0812 32.2729C34.2075 32.0503 34.4099 31.8809 34.6512 31.7958C35.2346 31.5902 36.0453 31.2519 36.8834 30.7366C34.3523 29.901 32.2927 28.4522 30.93 26.4863C28.3609 22.7799 28.1596 18.6022 30.391 15.3108C32.5992 12.0539 36.6794 10.4649 40.545 11.3573C44.7709 12.3329 47.7393 16.0305 48.4855 21.2487C48.5165 21.4659 48.544 21.6821 48.5679 21.8974C49.6046 21.29 50.4423 20.5407 50.967 19.7019C52.2951 17.5792 52.4211 14.4112 51.2885 11.6312C50.5696 9.86687 49.4597 8.51758 48.208 7.83887C47.6497 7.53623 47.3522 6.90817 47.5018 6.29105L48.771 1.05746C48.9655 0.255931 49.8192 -0.205363 50.5895 0.0896956C54.1417 1.45011 57.0644 4.4757 58.7425 8.59381C60.8513 13.7693 60.4867 19.6613 57.7907 23.9708C55.6577 27.3802 51.7239 29.9233 47.1752 30.9983C46.1534 33.1347 44.6243 35.0115 42.6187 36.549C40.477 38.1906 38.2583 39.0938 36.8393 39.5512ZM38.4169 19.1648C37.8905 19.1639 37.3253 19.4259 37.0532 19.8276C36.6466 20.4272 37.1869 21.3842 37.5452 21.9011C38.0365 22.61 39.1803 23.1389 40.6212 23.3757C40.5994 23.0452 40.5648 22.7158 40.5175 22.388C40.2603 20.5901 39.5938 19.3984 38.7344 19.2001C38.6302 19.1764 38.5237 19.1645 38.4169 19.1648Z"
                          fill="url(#paint13_radial_1307_10889)"
                        />
                        <path d="M54.3382 27.689L48.5687 21.8984C48.5687 21.8984 48.9371 27.4897 47.1758 30.9996C49.8583 30.3657 52.3266 29.2207 54.3382 27.689Z" fill="url(#paint14_linear_1307_10889)" />
                        <path
                          d="M37.5455 21.9006C37.2831 21.5219 36.9245 20.9076 36.9104 20.3701L28.8506 20.3555C28.8631 22.3956 29.5593 24.5078 30.9303 26.4859C32.293 28.4519 34.3524 29.9008 36.8837 30.7362C36.8837 30.7362 41.0145 29.4831 40.6217 23.3752C39.1806 23.1384 38.0368 22.6095 37.5455 21.9006Z"
                          fill="url(#paint15_linear_1307_10889)"
                        />
                        <path
                          d="M54.6364 40.2322C52.7637 41.4533 51.1701 43.0563 49.9602 44.9362C49.6882 45.3587 49.0609 45.3576 48.7905 44.9341C47.5874 43.0497 45.9996 41.4409 44.1313 40.213C43.717 39.9409 43.7182 39.322 44.1334 39.0514C46.0061 37.8303 47.5997 36.2273 48.8097 34.3474C49.0815 33.925 49.709 33.926 49.9794 34.3496C51.1826 36.2339 52.7703 37.8427 54.6386 39.0707C55.0529 39.3428 55.0517 39.9617 54.6364 40.2322Z"
                          fill="url(#paint16_linear_1307_10889)"
                        />
                        <path
                          d="M88.8464 37.7713C86.9737 38.9923 85.3801 40.5954 84.1701 42.4753C83.8983 42.8977 83.2708 42.8967 83.0005 42.4732C81.7973 40.5888 80.2096 38.98 78.3413 37.752C77.9269 37.4799 77.9282 36.861 78.3434 36.5905C80.2161 35.3694 81.8097 33.7664 83.0197 31.8865C83.2915 31.464 83.919 31.4651 84.1893 31.8886C85.3925 33.7729 86.9803 35.3818 88.8485 36.6097C89.2629 36.8817 89.2616 37.5006 88.8464 37.7713Z"
                          fill="url(#paint17_linear_1307_10889)"
                        />
                        <path
                          d="M72.8259 10.4471C70.9532 11.6681 69.3596 13.2711 68.1496 15.1511C67.8777 15.5735 67.2503 15.5725 66.98 15.1489C65.7768 13.2646 64.1891 11.6557 62.3208 10.4278C61.9064 10.1557 61.9077 9.53682 62.3229 9.26629C64.1956 8.04517 65.7892 6.44216 66.9992 4.56229C67.271 4.13982 67.8985 4.14087 68.1688 4.5644C69.372 6.44871 70.9598 8.05755 72.828 9.28552C73.2424 9.55764 73.2411 10.1765 72.8259 10.4471Z"
                          fill="url(#paint18_linear_1307_10889)"
                        />
                        <path
                          d="M71.0918 29.2898C69.7267 30.18 68.565 31.3485 67.6829 32.7188C67.4847 33.0268 67.0273 33.0259 66.8304 32.7173C65.9534 31.3436 64.7959 30.1708 63.434 29.2757C63.1321 29.0774 63.1328 28.6263 63.4356 28.429C64.8007 27.5389 65.9624 26.3704 66.8445 25C67.0426 24.6921 67.5001 24.6928 67.697 25.0016C68.5741 26.3752 69.7315 27.548 71.0934 28.4431C71.3955 28.6415 71.3946 29.0926 71.0918 29.2898Z"
                          fill="url(#paint19_linear_1307_10889)"
                        />
                        <path
                          d="M76.6465 18.7039C75.2813 19.5941 74.1197 20.7626 73.2376 22.1329C73.0394 22.4409 72.582 22.44 72.385 22.1313C71.508 20.7577 70.3506 19.5849 68.9887 18.6898C68.6867 18.4914 68.6875 18.0404 68.9903 17.8431C70.3554 16.9529 71.5171 15.7844 72.3992 14.4141C72.5973 14.1061 73.0547 14.1069 73.2517 14.4157C74.1288 15.7893 75.2862 16.962 76.648 17.8572C76.95 18.0556 76.9491 18.5066 76.6465 18.7039Z"
                          fill="url(#paint20_linear_1307_10889)"
                        />
                      </g>
                      <defs>
                        <linearGradient id="paint0_linear_1307_10889" x1="12.4057" y1="44.0751" x2="38.526" y2="81.6778" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFE548" />
                          <stop offset="0.176" stop-color="#FFDE50" />
                          <stop offset="0.445" stop-color="#FFCA65" />
                          <stop offset="0.771" stop-color="#FFAA87" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1307_10889" x1="32.9342" y1="73.4299" x2="27.9261" y2="91.1971" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FBA5C2" stop-opacity="0" />
                          <stop offset="0.386" stop-color="#FCA0BA" stop-opacity="0.386" />
                          <stop offset="0.974" stop-color="#FF91A5" stop-opacity="0.974" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_1307_10889" x1="37.9791" y1="62.828" x2="31.7784" y2="85.2457" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FBA5C2" stop-opacity="0" />
                          <stop offset="0.386" stop-color="#FCA0BA" stop-opacity="0.386" />
                          <stop offset="0.974" stop-color="#FF91A5" stop-opacity="0.974" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_1307_10889" x1="42.1027" y1="50.651" x2="34.3519" y2="76.169" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FBA5C2" stop-opacity="0" />
                          <stop offset="0.386" stop-color="#FCA0BA" stop-opacity="0.386" />
                          <stop offset="0.974" stop-color="#FF91A5" stop-opacity="0.974" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_1307_10889" x1="46.5225" y1="41.2766" x2="38.2947" y2="59.8788" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFE548" />
                          <stop offset="0.176" stop-color="#FFDE50" />
                          <stop offset="0.445" stop-color="#FFCA65" />
                          <stop offset="0.771" stop-color="#FFAA87" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_1307_10889" x1="38.7554" y1="55.3382" x2="50.3817" y2="37.4519" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFD00D" stop-opacity="0" />
                          <stop offset="1" stop-color="#DB722B" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_1307_10889" x1="49.5432" y1="47.4812" x2="42.2097" y2="25.1231" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFD00D" stop-opacity="0" />
                          <stop offset="1" stop-color="#DB722B" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_1307_10889" x1="58.2303" y1="59.8072" x2="52.3278" y2="50.1484" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#A34A9E" stop-opacity="0" />
                          <stop offset="1" stop-color="#343168" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_1307_10889" x1="67.256" y1="56.9883" x2="67.531" y2="42.2425" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#A34A9E" />
                          <stop offset="1" stop-color="#343168" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_1307_10889" x1="21.2492" y1="12.5906" x2="24.4897" y2="14.0016" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#3FA9F5" />
                          <stop offset="1" stop-color="#666AD6" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_1307_10889" x1="13.5296" y1="17.6356" x2="16.9585" y2="16.7787" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#A34A9E" />
                          <stop offset="1" stop-color="#343168" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_1307_10889" x1="75.7522" y1="69.2803" x2="67.286" y2="74.4076" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF4974" />
                          <stop offset="0.45" stop-color="#FE4773" />
                          <stop offset="0.629" stop-color="#FC406D" />
                          <stop offset="0.76" stop-color="#F73464" />
                          <stop offset="0.868" stop-color="#F12257" />
                          <stop offset="0.961" stop-color="#E80C46" />
                          <stop offset="1" stop-color="#E4003D" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_1307_10889" x1="77.2592" y1="58.7747" x2="73.3242" y2="70.1028" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#C4F8B6" />
                          <stop offset="1" stop-color="#3B8EAC" />
                        </linearGradient>
                        <radialGradient id="paint13_radial_1307_10889" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.094 6.52152) scale(35.4279 35.4279)">
                          <stop stop-color="#FF4974" />
                          <stop offset="0.45" stop-color="#FE4773" />
                          <stop offset="0.629" stop-color="#FC406D" />
                          <stop offset="0.76" stop-color="#F73464" />
                          <stop offset="0.868" stop-color="#F12257" />
                          <stop offset="0.961" stop-color="#E80C46" />
                          <stop offset="1" stop-color="#E4003D" />
                        </radialGradient>
                        <linearGradient id="paint14_linear_1307_10889" x1="55.3755" y1="31.7312" x2="42.5867" y2="22.5196" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#E4003D" stop-opacity="0" />
                          <stop offset="0.343" stop-color="#CD0E2D" stop-opacity="0.343" />
                          <stop offset="1" stop-color="#972E07" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_1307_10889" x1="35.9807" y1="25.1719" x2="39.5581" y2="28.4809" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#E4003D" stop-opacity="0" />
                          <stop offset="0.343" stop-color="#CD0E2D" stop-opacity="0.343" />
                          <stop offset="1" stop-color="#972E07" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_1307_10889" x1="47.6383" y1="37.1267" x2="53.5409" y2="44.9967" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#3FA9F5" />
                          <stop offset="1" stop-color="#666AD6" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_1307_10889" x1="81.8437" y1="34.6033" x2="87.7463" y2="42.4733" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#3FA9F5" />
                          <stop offset="1" stop-color="#666AD6" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_1307_10889" x1="65.7736" y1="7.30852" x2="71.676" y2="15.1786" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#A34A9E" />
                          <stop offset="1" stop-color="#343168" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_1307_10889" x1="65.9904" y1="26.9689" x2="70.2931" y2="32.706" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#C4F8B6" />
                          <stop offset="1" stop-color="#3B8EAC" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_1307_10889" x1="71.5256" y1="16.3727" x2="75.8283" y2="22.1098" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFE548" />
                          <stop offset="0.176" stop-color="#FFDE50" />
                          <stop offset="0.445" stop-color="#FFCA65" />
                          <stop offset="0.771" stop-color="#FFAA87" />
                          <stop offset="1" stop-color="#FF90A4" />
                        </linearGradient>
                        <clipPath id="clip0_1307_10889">
                          <rect width="90" height="90" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p class="font-weight-bold lh-28 fs-24 ml-md-2"><span>20% of Brokerage</span></p>
                  <p class="font-weight-light txt-444 ml-md-2 mb-2">Get 20% of brokerage costs for trades made by your friends.</p>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",

    clientid: "",
    btnenable: true,
    btnload: false,
    sharetextweb: "https://wa.me/?text=Click this link to open a Trading account with Zebu and Gain an unified Trading and Investment Experience. ",
  }),
  methods: {
    getrefferalUrl() {
      this.btnload = true;
      var respdata = "https://oa.mynt.in/?ref=";
      var config = {
        method: "get",
        url: "https://dwld.zebuetrade.com/gen?id=" + this.clientid,
        headers: {},
      };

      let axiosThis = this;
      axios(config)
        .then(function (response) {
          if (response.data.link != "Invalid") {
            axiosThis.clientid = respdata + axiosThis.clientid;
            axiosThis.btnenable = false;
            const element = document.getElementById("content");
            element.scrollIntoView();
          } else {
            axiosThis.clientid = null;
            axiosThis.btnenable = true;
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "#2A2A2A";
            axiosThis.snackmsgbar = "Invalid Client ID.";
          }
          axiosThis.btnload = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    nullClients() {
      this.clientid = null;
      this.btnenable = true;
      this.btnload = false;
    },

    shareViaWebShare() {
      navigator.share({
        title: "Zebu",
        text: "Zebu offers trading and investment opportunities. Create an account right away. ",
        url: this.clientid,
      });
    },
    copylink() {
      navigator.clipboard.writeText(this.clientid);
      this.snackbar = true;
      this.snackbarclr = "#2A2A2A";
      this.snackmsgbar = "Link as copied.";
    },
  },
};
</script>
