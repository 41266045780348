<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">NPS Calculator</p>
                            <p class="mb-0 title font-weight-regular">Principal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principalsamounts" block flat solo background-color="#ffffff"
                                 min="1"
                                class="calc-field elevation-0 rounded-pill mb-3" prefix="₹" label="Enter principal paid"
                                hide-details>
                            </v-text-field>
                            <v-row class="mt-8">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestratesvalue"
                                        hide-spin-buttons min="1" max="20" step="1"
                                        ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestratesvalue" ticks="always"
                                        tick-size="6" min="1" max="20" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiodvalue"
                                        hide-spin-buttons min="1" max="50" step="1"
                                        ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiodvalue" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Returns on annuity</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="returnsannuityvalue"
                                        hide-spin-buttons min="1" max="20" step="1"
                                        ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="returnsannuityvalue" ticks="always"
                                        tick-size="6" min="1" max="20" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32 mt-6 mt-md-0">NPS Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="pa-4">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                            <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Principal
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Interest
                                                Earned</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Annuity
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Pension
                                                Wealth</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emitotal ?
                                                emitotal : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                            <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32 mt-6 mt-md-0">NPS Retirement</p>
                            <v-card class="elevation-0 rounded-lg d-inline-flex overflow-hidden" height="45px" width="100%"
                                color="transparent">
                                <v-card class="elevation-0 rounded-r-0 rounded-l-lg m-1" height="45px"
                                    :width="`${100 - perc}%`" color="#AAAAAA"></v-card>

                                <v-card v-if="perc" class="elevation-0 rounded-l-0 rounded-r-lg d-inline-flex" height="45px"
                                    :width="`${perc}%`" color="transparent">
                                    <div v-for="n in 1000" :key="n" class="pad-x-2">
                                        <v-card class="elevation-0 rounded-lg d-inline-flex" height="45px" width="10px"
                                            color="#6EB94B">
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-card>
                            <v-row no-glutters class="mt-3">
                                <v-col cols="12" md="6">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Lumpsum
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ lumpsumamount ?
                                                lumpsumamount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Pension per
                                                month</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ pensionamount ?
                                                pensionamount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="pa-6 px-sm-8 px-md-10">
                    <p class="mb-6 font-weight-light txt-444">With this calculator, you can figure out how much you will
                        get from your pension and lump sum when you retire. The choice you have to make is how much to put
                        into the NPS each month. The more money you put into investments, the more money you can save, and
                        the huger the pension would be in the long run. NPS is a good way to save for retirement because of
                        the power of monthly compounding. <br><br>

                        NPS is a defined-contribution plan. It lets the NPS subscriber put money into their NPS account on a
                        regular basis while they work. The money saved can be used to buy an annuity in the future. The
                        scheme is made to be a long-term method for all Indian citizens to have enough money to live on when
                        they retire.</p>
                    <p class="mb-0 font-weight-bold title">What is the NPS calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">The NPS calculator lets you figure out how much you will
                        get each month and all at once when they retire. The calculation is based on the monthly
                        contribution, the expected annual rate of return on the investment, the percentage of the corpus
                        that is used to buy an annuity, and the expected annual rate of return on the annuity.</p>

                    <p class="mb-0 font-weight-bold title">How to Use the NPS Calculator?</p>
                    <p class="font-weight-light txt-444">
                    <ul>
                        <li>Age of retirement</li>
                        <li>Investment amount</li>
                        <li>Expected returns</li>
                        <li>Percentage of corpus used to purchase annuity</li>
                        <li>Annuity rate</li>
                    </ul>
                    </p>

                    <p class="mb-0 font-weight-light txt-444">Once you put in these numbers, our calculator will figure
                        out how much you will get as a pension and as a lump sum when you retire.</p>
                </div>
            </v-card>
        </div>
    </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default {
    data() {
        return {
            Principalsamounts: 200000,
            interestratesvalue: 8,
            tenureperiodvalue: 2,
            returnsannuityvalue: 5,
            emireport: 0,
            emiammount: 0,
            emiinterest: 0,
            emitotal: 0,
            lumpsumamount: 0,
            pensionamount: 0,
            // ///////////////////////////
            perc: 0,
            doughnuts: [],
        }
    },
    mounted() {
        this.emicalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.emicalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.emicalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        emicalculator() {
            // console.log("PPPPP", this.Principalsamounts)
            var Principleammount = parseFloat(this.Principalsamounts);

            var InterestRate = parseFloat(this.interestratesvalue / (100));
            // console.log("interst value change", InterestRate)
            var year = parseFloat(this.tenureperiodvalue);
            var pensionpercent = parseFloat(this.returnsannuityvalue / (100));
            // console.log("pensionpercent value change", pensionpercent)
            var age = parseFloat((60 - year) * 12);
            // console.log("age value", age)
            var npsclosing = 0;
            for (var i = 1; i <= age; i++) {
                // console.log("1ST", npsclosing ,"times",i)
                // var npsclosing = 0
                npsclosing = (npsclosing + Principleammount) * (1 + InterestRate / 12)
                // console.log("npstotalcontribution", npsclosing, "times", i)
            }
            var npsclosingamount = Math.round(npsclosing)
            // console.log("npsclosingamount value:", npsclosingamount)
            var invested = Principleammount * age
            // console.log("invested value:", invested)
            var intrestEarned = npsclosingamount - invested;
            // console.log("intrestEarnedvalue:", intrestEarned)
            var annuityamount = npsclosingamount * 0.40
            // console.log("annuityamount value:", annuityamount)
            var lumpsum = npsclosingamount * 0.60
            // console.log("lumpsumvalue:", lumpsum)
            var pension = (annuityamount * pensionpercent) / 12
            // console.log("pensionvalue:", pension)


            this.emireport = (Math.round(annuityamount).toLocaleString());

            this.emiammount = (Math.round(invested).toLocaleString());
            this.emiinterest = (Math.round(intrestEarned).toLocaleString());
            this.emitotal = (Math.round(npsclosing).toLocaleString());
            this.lumpsumamount = (Math.round(lumpsum).toLocaleString());
            this.pensionamount = (Math.round(pension).toLocaleString());
            this.doughnuts = [invested, annuityamount, intrestEarned]
            this.perc = Math.round((100 * Number(pension)) / (Number(lumpsum) + Number(pension)));
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#AAAAAA', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#AAAAAA', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }
}
</script>