<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Lumpsum intrest calculator</p>
                    <p class="title mb-1">Estimate the future value of a one-time investment with the power of compounding.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Investment amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle
                                                class="mb-2 fs-18 font-weight-regular">Est.intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is a lumpsum investment?</p>
                    <p class="mb-6 font-weight-light txt-444">Lumpsum investment, also called "one-time investment," is a
                        type of investment in which you invest all of your money at once (in a lump sum) and let it earn
                        compounding returns over a certain amount of time.
                    </p>
                    <p class="mb-0 font-weight-bold title">What Is a Lumpsum Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">With the Lumpsum calculator, you can figure out how much
                        your investment will be worth when it comes due. In other words, the Lumpsum Calculator tells you
                        how much your money will be worth in the future if you invest it today at a certain interest rate.
                        For example, if you invest 10,000 rupees for 60 years at an interest rate of 15%, your investments
                        will be worth a mind-boggling 4.3 crores after 60 years, according to the lumpsum calculator.
                    </p>
                    <p class="mb-0 font-weight-bold title">What does this Lumpsum Calculator do?</p>
                    <p class="mb-6 font-weight-light txt-444">Our lump sum calculator is very easy to use. In our Lumpsum
                        Calculator, all you have to do is enter the necessary information, such as the amount you are
                        willing to invest, the length of time (in years) you are willing to keep the money invested, and the
                        rate of return per year you think the investment will bring. After you put in the necessary
                        information, the calculator will tell you how much your investments will be worth in the future.
                        <br><br>
                        This Lumpsum Calculator uses the following formula: Value = Investment * (1+R)N
                    </p>
                    <p class="mb-0 font-weight-bold title">When should Lumpsum Investment be chosen?</p>
                    <p class="mb-6 font-weight-light txt-444">Ideally, any investment—whether it's a lump sum or a
                        systematic investment plan (SIP)—should take into account things like the investor's current income,
                        risk profile, age, tax constraints, liquidity needs, time frame, and a few other unique factors.
                        People prefer lump-sum investments when they have a lot of extra money and, more importantly, when
                        they think the market has already changed a lot or won't drop right after they invest. Investing a
                        lump sum over a longer time period helps the rate of return to grow.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is the difference between Lumpsum and SIP?</p>
                    <p class="mb-6 font-weight-light txt-444">In a lump-sum investment, you only have to invest once. In
                        a Systematic Investment Plan, or SIP, you invest a fixed amount at regular intervals. In a lump-sum
                        investment, the market condition is very important because if the market drops a lot after you
                        invest, it could take a few years to get back to the amount you put in. With SIP, or systematic
                        investment, one doesn't have to worry about when to invest in the market because investments are
                        made during both ups and downs. So, the generated return is the weighted average return.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 10000,
            interestrate: 10,
            tenureperiod: 2,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var Principleammount = this.Monthlyinvestment;
            var InterestRate = this.interestrate / (100);
            var TenurePeriodYear = this.tenureperiod;
            var first = Math.pow(1 + InterestRate, TenurePeriodYear)
            var second = Math.round(Principleammount * first);
            var third = Math.round(second - Principleammount);
            this.emireport = (Principleammount.toLocaleString());
            this.emiammount = (third.toLocaleString());
            this.emiinterest = (second.toLocaleString());
            this.doughnuts = [Principleammount, third];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>