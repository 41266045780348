<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl text-center d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Inactive Account</p>
                <p class="white--text headline">Inactive Account
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 text-center d-md-none">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Inactive Account</p>
                <p class="white--text fs-18">Inactive Account
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 pa-md-16 mb-md-6 mx-md-16">
            <div class="px-md-16">
                <div v-for="(i, a) in inactiveitems" :key="a">
                    <p>
                        <span class="font-weight-bold">{{ i.title }}</span> <span class="font-weight-light lh-28">{{ i.content }}</span>
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        inactiveitems: [
            {
                title: "Definition",
                content:"The client who did not execute any trades during the last 24 months across exchanges and segments will be considered inactive.",
            },
            {
                title: "Treatment of Inactive Clients",
                content:"Calculation will be done at the beginning of every month, and those clients who have not traded even a single time will be considered inactive. The commodities, securities, and credit ledger balances, if any, will be transferred to the client's bank account or demat account before marking the client as inactive",
            },
            {
                title: "Reactivation of Inactive Account",
                content:"The client has to make a written request for the reactivation of their account. Such dormant accounts are blocked from trading until re-activation. The client cannot place an order from the inactive account unless the blockage is cleared.",
            },
            {
                title: "Treatment of Non Traceable Client Funds",
                content:"We, ZEBU, will settle the idle funds available in the client's ledger for those who have not executed any trade in the past 30 days as per the recent regulatory changes or running account settlement cycle (30/90 days) opted by the client at the time of account opening.",
            },
        ]
    }),
}
</script>