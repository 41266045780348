<script>
export default {
    created() {
        if ((/iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) {
            window.location.href = 'https://apps.apple.com/in/app/mynt/id6478270319'
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu'
        } 
        // if (/Android/i.test(navigator.userAgent))
    }
}
</script>