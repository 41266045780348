<template>
    <div class="sec-footer">
        <v-footer padless color="white">
            <v-card color="#CCD2D3" width="100%" height="2px" class="elevation-0 rounded-0"></v-card>
            <v-container class="pa-0">

                <v-card class="mx-auto elevation-0 rounded-0 pt-10 pt-md-6" width="90%">
                    <div>
                        <div data-aos="flip-up" class="sec-four py-0 py-md-6">
                            <v-card class="rounded-c-pill pt-2 pb-1 px-md-3 elevation-0" color="#212424" width="100%">
                                <div class="wrapper">
                                    <div class="secfour-scroll overflow-hidden">
                                        <p class="mb-0 white--text">

                                            <span class="px-4 subtitle-2  font-weight-thin">{{ secfouritems }}
                                                <a href="tel:+91 93 8010 8010" target="_blank" rel="noopener noreferrer"
                                            class="mailto-txt-addsD">
                                            (+91) 93 8010 8010</a>
                                            </span>

                                        </p>
                                        <p class="mb-0 white--text">

                                            <span class="px-4 subtitle-2  font-weight-thin white--text">{{ secfouritems }}
                                                <a href="tel:+91 93 8010 8010" target="_blank" rel="noopener noreferrer"
                                            class="mailto-txt-addsD">
                                            (+91) 93 8010 8010</a>
                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </v-card>
                        </div>
                        <v-row no-gutters>
                            <v-col cols="12" md="4">
                                <v-row no-glutters>
                                    <v-col cols="1" md="12">
                                        <img src="https://zebuetrade.com/asset/static/logo/mainlogo.svg" width="90px" alt="zebulogo">
                                    </v-col>
                                    <v-col cols="11" class="d-md-none text-right">
                                        <v-btn v-for="(m, n) in footermediabtn" :key="n" x-small color="#E5EBEC" fab
                                            :href="m.link" target="_blank" rel="noopener noreferrer" class="elevation-0"
                                            :class="n != footermediabtn.length - 1 ? 'mr-2' : 'mr-0'">
                                            <img :src="m.image" width="60%" :alt="m.image">
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <div class="pt-8 pt-md-10">
                                    <p class="mb-0 d-none d-md-flex" v-for="(b, c) in footerbtn" :key="c">
                                        <v-btn :to="b.link" height="16px" text :ripple="false" color="#444444"
                                            class="text-capitalize pa-0 footbtns mb-3"><span
                                                class="subtitle-1 font-weight-bold">{{ b.text }} <v-icon color="primary"
                                                    size="18">mdi-chevron-right</v-icon></span>
                                        </v-btn>
                                    </p>

                                    <div class="d-md-none">
                                        <v-btn v-for="(b, c) in footerbtn" :key="c" :to="b.link" height="16px" block
                                            text color="#666666" :ripple="false"
                                            class="text-capitalize pa-0 footbtns mb-2"><span
                                                class="subtitle-2 font-weight-bold">{{ b.text }} <v-icon color="primary"
                                                    size="18">mdi-chevron-right</v-icon></span>

                                        </v-btn>
                                        <v-divider class="mt-4"></v-divider>
                                    </div>


                                    <div class="d-none d-md-inline-flex mt-6">
                                        <v-btn v-for="(m, n) in footermediabtn" :key="n" small color="#E5EBEC" fab
                                            :href="m.link" target="_blank" rel="noopener noreferrer"
                                            class="pa-1 elevation-0 mr-2">
                                            <img :src="m.image" width="60%" :alt="m.image">
                                        </v-btn>
                                    </div>

                                    <p class="footer-txt-sty body-2 font-weight-bold mt-8 mb-0">
                                        <img src="@/assets/contactus/phone.svg" width="14px" class="mr-2"
                                            alt="phone-icon">
                                        <a href="tel:+91 93 8010 8010" target="_blank" rel="noopener noreferrer"
                                            class="mailto-txt-adds">
                                            (+91) 93 8010 8010</a>
                                    </p>
                                    <p>
                                        <img src="@/assets/contactus/send-message.svg" width="14px" class="mr-2"
                                            alt="send-message-icon">

                                        <a class='mailto-txt-adds font-weight-bold' target="_blank"
                                            rel="noopener noreferrer" href='mailto:grievance@zebuetrade.com'>
                                            <img src="@/assets/assistZebuMaill.png" alt='mail' class="mt-1">
                                            <!-- assist@zebuetrade.com -->
                                        </a>
                                    </p>

                                    <!-- <v-row no-gutters class="mb-8">
                                        <v-col  class="pr-0"><span
                                                class="txt-444 font-weight-light ">Investor grievance: <span class="mt-10"><img
                                                src="@/assets/grievanceZebuMail.png" alt='mail'></span>
                                            </span></v-col>
                                        <v-col cols="12" lg="6" md="7" class="pl-0 ml-lg-n2"><img
                                                src="@/assets/grievanceZebuMail.png" alt='mail'></v-col>
                                    </v-row> -->

                                    <p>
  <span class="txt-444 font-weight-light">Investor grievance:</span>
  <a href="mailto:grievance@zebuetrade.com" target="_blank" rel="noopener noreferrer" style="color: #666666 !important; text-decoration: none;font-weight: 800;">
    grievance@zebuetrade.com
  </a>
</p>

                                    <div>
                                        <p class="footer-txt-sty body-2"><span class="font-weight-bold">Zebu Share and
                                                Wealth Managements
                                                Pvt.
                                                Ltd.</span> <br>
                                            <span class="txt-444 font-weight-regular">
                                                Registration No: INZ000174634<br>
                                                CDSL: 12080400<br>
                                                AMFI ARN: 113118<br>
                                                Research Analyst: INH200006044</span>
                                        </p>

                                        <p class="footer-txt-sty body-2 pt-4"><span class="font-weight-bold">Head
                                                office</span> <br>
                                            <span class="txt-444 font-weight-regular">
                                                <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                                    href="https://maps.app.goo.gl/wt6pSw2VjR2iiAj48">
                                                    No: 301, 4th Main Road, Burma Colony,<br>
                                                    Perungudi, Chennai, Tamil Nadu 600 096.<br>
                                                </a>
                                                <span class="font-weight-bold">Phone : </span>
                                                <a href="tel:+91 93 8010 8010" target="_blank" rel="noopener noreferrer"
                                                    class="mailto-txt-adds">
                                                    (+91) 93 8010 8010 </a></span>

                                        </p>

                                        <p class="footer-txt-sty body-2 pt-4"><span class="font-weight-bold">Registered
                                                office</span> <br>
                                            <span class="txt-444 font-weight-regular">
                                                <a target="_blank" class="mailto-txt-adds" rel="noopener noreferrer"
                                                    href="https://goo.gl/maps/9JtKT2a4WSSKQvCZ7">
                                                    036, KRISHNA COMPLEX VCTV MAIN ROAD (WARD <br>
                                                     NO-28), Erode, ERODE, Tamil Nadu, India, 638003
                                                    <!-- No: 36, Ground Floor, Sri Krishna Complex, ( opp<br>
                                                    to IDBI bank ), Sathy Road, Erode -638 003. -->
                                                    <br></a></span>
                                        </p>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="8" class="pl-0 pl-md-8">
                                <div class="d-md-inline-flex">
                                    <v-autocomplete @change="goCompliance()" item-text="text" item-value="too"
                                        v-model="complianc" append-icon="mdi-chevron-down"
                                        class="footer-select rounded-pill mr-md-4 mb-6 mb-md-0" :items="compliancelinks"
                                        label="Compliance links" solo hide-details></v-autocomplete>
                                    <v-autocomplete @change="goCalculator()" item-text="text" item-value="too"
                                        v-model="calcs" append-icon="mdi-chevron-down"
                                        class="footer-select rounded-pill" :items="calcitems"
                                        label="Investment Calculators" solo hide-details></v-autocomplete>
                                </div>
                                <div class="mt-8 mb-6">
                                    <v-btn to="/privacy" height="16px" text :ripple="false"
                                        class="text-capitalize pa-0 footbtns mr-2"><span
                                            class="subtitle-2 font-weight-bold">Privacy Policy</span></v-btn> |
                                    <v-btn to="/termsandconditions" height="16px" text :ripple="false"
                                        class="text-capitalize pa-0 footbtns mx-2"><span
                                            class="subtitle-2 font-weight-bold">Terms and Conditions</span></v-btn> |
                                    <v-btn to="/disclaimer" height="16px" text :ripple="false"
                                        class="text-capitalize pa-0 footbtns mx-2"><span
                                            class="subtitle-2 font-weight-bold">Disclaimer</span></v-btn>
                                    |
                                    <v-btn href="https://zebuetrade.com/asset/static/Downloads/download_docs/Brochure.pdf" target="_blank" height="16px" text :ripple="false"
                                        class="text-capitalize pa-0 footbtns mx-2 mt-1"><span
                                            class="subtitle-2 font-weight-bold">E-Brochure</span></v-btn>|
                                            <v-btn href="https://zebuetrade.com/asset/static/Downloads/download_docs/AP-Brochure.pdf" target="_blank" height="16px" text :ripple="false"
                                        class="text-capitalize pa-0 footbtns mx-2 mt-1"><span
                                            class="subtitle-2 font-weight-bold">AP Brochure</span></v-btn>
                                </div>

                                <div class="footer-txt-sty subtitle-1">
                                    <p class="font-weight-bold mb-2">
                                        The following
                                        advice is
                                        issued in
                                        the interest
                                        of investors
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Stock Brokers can accept securities as margin from clients only by way of
                                        pledge
                                        in
                                        the depository
                                        system w.e.f. September 01, 2020.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Update your email id and mobile number with your stock broker / depository
                                        participant and receive OTP directly from depository on your email id and/or
                                        mobile number to create pledge.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Check your securities / MF / bonds in the consolidated account statement issued
                                        by NSDL/CDSL every month
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Safeguard your account from unauthorized transactions. Update your mobile
                                        numbers/email IDs with your stock brokers. Get all information related to your
                                        transactions directly from the stock exchanges on your mobile phone/email id, at
                                        the end of every day. KYC compliance is mandatory when you enter the securities
                                        market. It is a one-time exercise done through a SEBI-registered intermediary
                                        (stockbroker, depository participant, mutual fund, etc). There is no need to
                                        repeat the KYC process when you go to any other intermediary.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Also please note that 'investment in the securities market are subject to market
                                        risks, read all the related documents carefully before investing'.
                                        <br>
                                        Brokerage will not exceed the SEBI prescribed limit.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        You do not have to issue a cheque while subscribing to an IPO. Write your bank
                                        account number clearly on the IPO application and sign it, sanctioning your bank
                                        to make payments when there is an allotment. Your funds will remain in your bank
                                        account in the case of non-allotment.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        We hereby declare that we are doing PRO trading.
                                    </p>
                                    <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                        Display of information : Filing Complaints on SCORES - Easy & quick <br>
                                    <ul>
                                        <!-- https://scores.gov.in/scores/Welcome.html -->
                                        <li>Register on <a target="_blank" rel="noopener noreferrer"
                                                class="mailto-txt font-weight-medium"
                                                href="https://scores.sebi.gov.in/">SCORES</a> portal
                                        </li>
                                        <li>Mandatory details for filing complaints on SCORES:</li>
                                        <ul>
                                            <li>Name, PAN, Address, Mobile Number, Email ID</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>Benefits: </li>
                                        <ul>
                                            <li>Effective Communication </li>
                                            <li>Speedy redressal of the grievances</li>
                                        </ul>
                                    </ul>
                                    </p>
                                </div>
                                <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                    CDSL e-Voting Link : <a target="_blank" rel="noopener noreferrer"
                                        class="mailto-txt font-weight-medium"
                                        href="https://evoting.cdslindia.com/Evoting/EvotingLogin">https://evoting.cdslindia.com/Evoting/EvotingLogin</a>
                                </p>

                                <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                    Online Dispute Resolution (ODR) :<a target="_blank" rel="noopener noreferrer"
                                        class="mailto-txt font-weight-medium"
                                        href="https://smartodr.in/login ">https://smartodr.in/login</a>
                                </p>
                                <p class="txt-444 font-weight-regular subtitle-2 mb-2">
                                    Clients / investors are requested to refrain from dealing in any schemes of
                                    unauthorised collective investments / portfolio management, indicative / guaranteed
                                    / fixed returns / payments etc.
                                </p>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row no-glutters class="mt-2">
                            <v-col cols="12" sm="6" class="text-center text-md-left d-md-inline-flex">
                                <span class="txt-444 font-weight-regular mr-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.nseindia.com/">NSE</a></span> |
                                <span class="txt-444 font-weight-regular mx-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.bseindia.com/">BSE</a></span> |
                                <span class="txt-444 font-weight-regular mx-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.mcxindia.com/">MCX</a></span> |
                                <span class="txt-444 font-weight-regular mx-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.msei.in/">MSEI</a></span> |
                                <span class="txt-444 font-weight-regular mx-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.cdslindia.com/index.html">CDSL</a></span> |
                                <span class="txt-444 font-weight-regular ml-2 subtitle-2">
                                    <a target="_blank" rel="noopener noreferrer" class="mailto-txt-adds"
                                        href="https://www.sebi.gov.in/">SEBI</a></span>
                            </v-col>
                            <v-col class="text-center text-sm-right" cols="12" sm="6">
                                <p class="mb-0 text-444444 subtitle-2">&copy; {{ year }} All rights reserved. Zebu.</p>
                            </v-col>

                        </v-row>
                    </div>
                </v-card>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
export default {
  data: () => ({
    year: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 4),
    complianc: null,
    calcs: null,
    calcitems: [
      { text: "Brokerage Calculator", too: "/calculator/brokerage" },
      { text: "Span Calculator", too: "/calculator/span" },
      { text: "CAGR Calculator", too: "/calculator/cagr" },
      { text: "Car Loan Calculator", too: "/calculator/carloan" },
      {
        text: "Compound Interest Calculator",
        too: "/calculator/compoundinterest",
      },
      { text: "EMI Calculator", too: "/calculator/emi" },
      { text: "EPF Calculator", too: "/calculator/epf" },
      { text: "Fixed-deposit Calculator", too: "/calculator/fixeddeposit" },
      {
        text: "Flat & Reducing Intrest calculator",
        too: "/calculator/flatvsreduce",
      },
      { text: "Gratuity Calculator", too: "/calculator/gratuity" },
      { text: "GST Calculator", too: "/calculator/gst" },
      { text: "Home Loan Calculator", too: "/calculator/homeloan" },
      { text: "HRA Calculator", too: "/calculator/hra" },
      { text: "Lumpsum Intrest Calculator", too: "/calculator/lumpsum" },
      { text: "Mutual Fund Calculator", too: "/calculator/mutualfund" },
      // { text: 'Goal Planner Calculator', too: '/calculator/goalplanner' },
      { text: "NPS Calculator", too: "/calculator/nps" },
      { text: "PPF Calculator", too: "/calculator/ppf" },
      { text: "RD Calculator", too: "/calculator/rd" },
      { text: "Simple Interest Calculator", too: "/calculator/simpleinterest" },
      { text: "SIP Calculator", too: "/calculator/sip" },
      { text: "SSY Calculator", too: "/calculator/ssy" },
    ],
    compliancelinks: [
      { text: "Bank Details", too: "/bank" },
      { text: "Approved Pledge Stock", too: "/pledge" },
      { text: "CSR", too: "/csr" },
      { text: "Online Payment", too: "/onlinepayment" },
      { text: "Blog policy", too: "/blogpolicy" },
      { text: "Disposal of Complaints", too: "/disposalcomplaints" },
      { text: "Anti Money Laundering", too: "/antimoneylaunderingpolicy" },
      { text: "Invester Charter", too: "/investorcharter" },
      { text: "Holding", too: "/holding" },
      { text: "RMS policy", too: "/rmspolicy" },
      { text: "No Holding", too: "/noholding" },
      { text: "Inactive Account", too: "/inactiveacct" },
      { text: "Reports", too: "/reports" },
      { text: "Depository Investor Charter", too: "/depositinvestorchart" },

      // { text: 'Privacy Policy', too: '/privacy' },
      // { text: 'Terms and Conditions', too: '/termsandconditions' },
      // { text: 'Disclaimer', too: '/disclaimer' },
    ],
    items: [],
    footerbtn: [
      { text: "Contact Us", link: "/contactus" },
      { text: "Brokerage Calculator", link: "/calculator/brokerage" },
      { text: "Span Calculator", link: "/calculator/span" },
      { text: "Downloads", link: "/downloads" },
      { text: "Blogs", link: "/blogs" },
      { text: "Career", link: "/career" },
      { text: 'Whistle Blower', link: '/whistleblower' },
    ],
    footermediabtn: [
      {
        image: require("@/assets/footer/facebook.svg"),
        link: "https://www.facebook.com/zebuetrade/",
      },
      {
        image: require("@/assets/footer/twitter-x.svg"),
        link: "https://twitter.com/zebuetrade?lang=en",
      },
      // { image: require('@/assets/footer/indeed.svg'), link: 'https://in.linkedin.com/company/zebu-share-and-wealth-managements-pvt-ltd' },
      {
        image: require("@/assets/footer/youtube.svg"),
        link: "https://www.youtube.com/channel/UCKbEVG1fH1TwkNDe6OM-zxg",
      },
      {
        image: require("@/assets/footer/instagram.svg"),
        link: "https://www.instagram.com/zebu_official/",
      },
      {
        image: require("@/assets/footer/pinterest.svg"),
        link: "https://in.pinterest.com/ZebuMarketing/",
      },
    ],
    secfouritems:
      "Beware of Imposters! Beware of fraudulent groups posing as ZEBU offering stock tips! Let's stay together and ensure our investments remain secure. Stay engaged with the current updates. Call ",
  }),

  methods: {
    goCompliance() {
      // let pageis = this.compliancelinks.find(o => o.text == );
      this.$router.push(this.complianc);
    },
    goCalculator() {
      // let pageis = this.calcitems.find(o => o.text == this.calcs);
      this.$router.push(this.calcs);
    },
  },
};
</script>