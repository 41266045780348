<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Goal planner</p>
                    <p class="title mb-1">Set financial goals and calculate how much to invest regularly to achieve them.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <div class="d-inline-flex mb-8" style="width:100%;">
                        <v-card @click="setgoalField(g)" :color="goalis == g.idx ? '#44444411' : ''"
                            class="elevation-0 rounded-lg py-3" :class="l != goalsitems.length - 1 ? 'mr-3' : ''"
                            v-for="(g, l) in goalsitems" :key="l" width="100%">
                            <div class="text-center">
                                <img :src="g.img" width="60px" :alt="g.txt">
                                <p class="fs-12 font-weight-medium mt-auto mb-0">
                                    {{ g.txt }}</p>
                            </div>
                        </v-card>
                    </div>

                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Goal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Expected interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always"
                                        tick-size="6" min="1" max="20" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details
                                        color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Period to achieve goal</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details
                                        color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Expected Inflation Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="inflationrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="inflationrate" ticks="always"
                                        tick-size="6" min="1" max="20" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details
                                        color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row class="pt-8">
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                    <v-btn target="_blank" :disabled="Number(emiammount) > 0 ? false : true" rel="noopener noreferrer" href="https://zebu.investwell.app/"
                                        height="48px" color="#2A2A2A"
                                        class="text-none rounded-pill elevation-0 mt-4 mb-8" block><span
                                            class="white--text subtitle-1 font-weight-medium px-2">Start
                                            investing</span></v-btn>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly
                                                investment</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                                }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment
                                                amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                                }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Est Goal
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireturn ?
                                                emireturn : '0.00'
                                                }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                returns</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                                }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <div class="">
                    <p class="mb-0 font-weight-bold title">
                        What is the formula to calculate Goal Planning returns?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The formula for a Goal planner calculator is FV = PV (1+R)^N
                        <br><br>
                        FV is the amount you future value<br>
                        PV is the amount you present value<br>
                        R is the rate of return on the investment<br>
                        N is the duration or time-frame of the investment.<br>
                    </p>
                    <p class="mb-0 font-weight-bold title">What is Goal Planning Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The goal planning calculator operates as a simulation, projecting the future value of a
                        financial objective based on the future value concept. Within the calculator, users input the
                        current value of their goal, the desired time horizon for achieving it, and the anticipated rate
                        of inflation. <br><br>
                        Subsequently, it computes the future value of the goal. Additionally, this
                        calculator provides guidance on the required savings amount to attain the financial goal,
                        considering the user's expected return on investment and any existing contributions towards the
                        goal.
                    </p>
                    <p class="mb-0 font-weight-bold title">Advantages of Goal planning investment?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        1. It is a Future Value Calculator: This Goal planning calculator offers you the futuristic
                        values of an investment, stimulating you to invest more systematically.
                        <br><br>
                        2. It Can Save Time from Manual Calculations: Manual calculations on how much you need to reach
                        a certain maturity amount can be time-consuming. This calculator provides you with a quick
                        resolution.
                        <br><br>
                        3. It Avoids Human Error: The generic mistakes that happen during manual calculations can be
                        avoided with the use of the Goal planning Calculator.
                        <br><br>
                        4. It Helps to Determine and Strategize an Investment Plan: When you understand how much you
                        have to invest each month to reach an estimated value, it assists you in strategizing and
                        planning your investments accordingly.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is Goal planning calculator how does work?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The Goal planning calculator operates through input parameters like the target amount,
                        timeframe, expected rate of return, and regular investment or savings amount.
                        <br><br>
                        Employing
                        intricate financial algorithms and mathematical computations, it estimates both the total funds
                        required to meet the goal and the necessary monthly or yearly savings or investment
                        contributions. With these inputs, the calculator then projects an approximate timeline for
                        achieving the specified financial objective.
                    </p>
                </div>

                </div>
            </v-card>
           
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 1000000,
            interestrate: 8,
            tenureperiod: 8,
            inflationrate: 7,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,
            emireturn: 0,

            doughnuts: [],

            goalsitems: [
                { img: require('@/assets/calculators/goalplan/retire.svg'), idx: 0, inf: 8, txt: "Retirement", amt: 4000000, ir: 8, per: 30 },
                { img: require('@/assets/calculators/goalplan/money.svg'), idx: 1, inf: 8, txt: "Crorepati (1 crore)", amt: 10000000, ir: 10, per: 25 },
                { img: require('@/assets/calculators/goalplan/home.svg'), idx: 2, inf: 8, txt: "Dream Home", amt: 8000000, ir: 8, per: 20 },
                { img: require('@/assets/calculators/goalplan/wedding.svg'), idx: 3, inf: 8, txt: "Dream Wedding", amt: 3000000, ir: 12, per: 5 },
                { img: require('@/assets/calculators/goalplan/education.svg'), idx: 4, inf: 8, txt: "Child's Education", amt: 2000000, ir: 10, per: 5 },
                { img: require('@/assets/calculators/goalplan/car.svg'), idx: 5, inf: 8, txt: "Dream Car", amt: 1000000, ir: 7, per: 5 },
                { img: require('@/assets/calculators/goalplan/others.svg'), idx: 6, inf: 8, txt: "Others", amt: 1000000, ir: 8, per: 10 },
            ],
            goalis: null,
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        setgoalField(g) {
            if (g != this.goalis) {
                this.goalis = g.idx;
                this.Monthlyinvestment = g.amt;
                this.interestrate = g.ir;
                this.tenureperiod = g.per;
                this.inflationrate = g.inf
                this.keyUpchartupdation();
            }
        },
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            // PV (1+(R/100))^N
            // (FV * ((IR /100)/12)) / (((1 + ((IR /100) / 12))^N) -1)

            var Principleammount = this.Monthlyinvestment;
            var InterestRate = this.interestrate / (100);
            var TenurePeriodYears = this.tenureperiod * 12;
            var inflation = this.inflationrate / (100)
            var first = (1 + inflation)
            var second = Math.pow(first, this.tenureperiod)
            var third = (Principleammount * second).toFixed(2);

            var emical = this.calculateMonthlyInvestment(InterestRate, TenurePeriodYears, third)

            this.emireturn = ((third - Principleammount).toLocaleString());
            this.emireport = (Principleammount.toLocaleString());
            this.emiammount = (third.toLocaleString());
            this.emiinterest = (emical.toLocaleString());
            this.doughnuts = [Principleammount, third];
        },
        calculateMonthlyInvestment(rate, periods, fv) {
            const monthlyRate = rate / 12;
            const monthlyInvestment = (fv * monthlyRate) / (Math.pow(1 + monthlyRate, periods) - 1);
            return monthlyInvestment.toFixed(2);
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>