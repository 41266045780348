<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Disclaimer</p>
                <p class="white--text headline">Disclaimer
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none">
                <p class="white--text fs-50 font-weight-bold mb-6">Disclaimer</p>
                <p class="white--text headline">Disclaimer
                </p>
            </v-card>
        </div>
        <div class="px-6 px-sm-8 pa-md-16 py-8 mb-md-6 mx-md-16">
            <div class="px-md-16">
                <p class="mb-0 font-weight-light lh-28">
                    This disclaimer governs the use of this site and constitutes the terms and conditions for providing
                    information on the site. When you use the site, you acknowledge that you have read the disclaimer and
                    you accept and agree to be bound by the terms mentioned therein.
                </p>
                <br>

                <p class="mb-0 font-weight-light lh-28">
                    This website and all the accompanying files do not contain warranties on the performance, information,
                    accuracy, timeliness, completeness, or any other warranties, whether expressed or implied. However, Zebu
                    works hard to keep the information on the site updated to its fullest ability.
                </p>
                <br>

                <p class="mb-0 font-weight-light lh-28">
                    Zebu is not liable for any technical failure, malfunction of software or delays of any kind. We are also
                    not responsible for non-receipt of registration details or e-mails. Users shall assume full
                    responsibility for keeping their password secure. Zebu is not responsible for the loss or misuse of the
                    password.
                </p>
                <br>

                <p class="mb-0 font-weight-light lh-28">
                    Also please note that, ‘Investment in the securities market are subject to market risks, read all the
                    related documents carefully before investing'
                </p>
                <br>
                <p class="mb-0 font-weight-light lh-28">
                    In case you have any grievances or suggestions, please write to <a  target="_blank" rel="noopener noreferrer"  class="mailto-txt"
                        href="mailto: grievance@zebuetrade.com">grievance@zebuetrade.com</a>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
    }),
}
</script>