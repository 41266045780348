<template>
    <div class="no-xhide">
        <div data-aos="fade-up" class="pos-rlt">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <v-row no-glutters>
                    <v-col cols="7">
                        <p class="white--text fs-50 font-weight-bold mb-6">Approved Pledge Stock</p>
                        <p class="white--text headline">All required documents and <br> compliance prerequisites in one <br>
                            place.
                        </p>

                    </v-col>
                    <v-col cols="5">
                        <v-container fill-height class="d-flex">
                            <v-card class="elevation-0 mt-auto" width="100%" color="transparent">
                                <p class="mb-2 ml-2 font-weight-thin white--text">What do you need help with?</p>
                                <v-text-field block flat solo background-color="#ffffff" v-model="search"
                                    class=" elevation-0 mr-3 rounded-pill caption mb-3" label="Search symbol" hide-details>
                                    <template #prepend-inner>
                                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                                    </template>
                                </v-text-field>
                            </v-card>
                        </v-container>

                    </v-col>
                </v-row>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <v-row no-glutters>
                    <v-col cols="12">
                        <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Approved Pledge Stock</p>
                        <p class="white--text fs-18">All required documents and compliance prerequisites in one
                            place.
                        </p>

                    </v-col>
                    <v-col cols="12">
                            <v-card class="elevation-0" width="100%" color="transparent">
                                <p class="mb-2 font-weight-thin white--text">What do you need help with?</p>
                                <v-text-field block flat solo background-color="#ffffff" v-model="search"
                                    class="elevation-0 rounded-pill caption mb-3" label="Search symbol" hide-details>
                                    <template #prepend-inner>
                                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                                    </template>
                                </v-text-field>
                            </v-card>

                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="py-md-16 mb-md-6 pa-6 px-sm-8 px-md-14">
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden">
                <v-data-table disable-sort :loading="loader" :search="search" :headers="headers" :items="pledges"
                    :items-per-page="10" :footer-props="{
                        'items-per-page-options': [10, 30, 50, 100, -1],
                    }" class="elevation-0 pledge-table"></v-data-table>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { zebumyntapi } from '../../apiurl'

export default {

    data: () => ({
        search: '',
        headers: [
            { text: 'Symbol', value: 'Symbol' },
            { text: 'ISIN', value: 'isin' },
            { text: 'Price', value: 'price' },
            { text: 'Haircut', value: 'haircut' },
        ],
        loader: true,
        pledges: [],
    }),

    mounted() {
        this.loader = true;
        let data = JSON.stringify({
            "ids": "6744"
        });

        let config = {
            method: 'get',
            url: zebumyntapi + 'ApprovedPledgeStock?id=P5BAaUHhiWNYSEWfgwuxUA',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        let axiosThis = this;
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                axiosThis.pledges = response.data.data;
                axiosThis.loader = false;
            })
            .catch((error) => {
                console.log(error);
                axiosThis.loader = false;
            });
    }

}
</script>