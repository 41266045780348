<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Online Payment</p>
                <p class="white--text headline">Online Payment
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 lh=38 font-weight-bold mb-6">Online Payment</p>
                <p class="white--text fs-18">Online Payment
                </p>
            </v-card>
        </div>
        <div class="py-md-10 mb-6 px-3 px-sm-5 px-md-16">
            <div class="pa-md-4">
                <v-row>
                    <v-col cols="3" class="pr-1 d-none d-md-flex">
                        <v-card width="100%" class="elevation-0">
                            <v-list dense nav>
                                <v-list-item-group v-model="panelselecte" @change="setScrollwindow()" class="list-group">
                                    <v-list-item v-for="(p, h) in onlinepay" :key="h">
                                        <v-list-item-content>
                                            <v-list-item-title
                                                :class="panelselecte == h ? 'primary--text' : 'txt-black'"
                                                class="font-weight-bold subtitle-1">{{ h + 1 }}. {{ p.title
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="9" class="mt-4">
                        <v-row justify="center" class="px-md-4">
                            <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 mb-6 mb-md-0">
                                <v-expansion-panel class="exp-sty" v-for="(p, h) in onlinepay" :key="h">
                                    <v-expansion-panel-header @click="setScrollwindow()" class="font-weight-bold subtitle-1 py-0 ml-md-3">{{ h + 1 }}. {{
                                        p.title }}
                                        <template v-slot:actions>
                                            <v-icon class="d-none d-md-block"></v-icon>
                                            <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-0 font-weight-light" v-html="p.content"></p>
                                    </v-expansion-panel-content>
                                    <v-divider></v-divider>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>
                    </v-col>
                </v-row>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        onlinepay: [
            {
                title: "Online Payments",
                content:
                    "This online payment system is provided by Zebu Share and Wealth Managements Pvt Ltd. Zebu Share and Wealth Managements Pvt Ltd may update these terms from time to time and any changes will be effective immediately on being set out here. Please ensure you are aware of the current terms. The country of domicile for Zebu Share and Wealth Managements Pvt Ltd is India",
            },
            {
                title: "Terms & Conditions",
                content:
                    "Please read these terms carefully before using the online payment facility. Using the online payment facility on this website indicates that you accept these terms. If you do not accept these terms do not use this facility.<br><br>All payments are subject to the following conditions:-<br><br><ul><li>The description of services of match making is specific to your need when you log in with your unique password. Normally payment is required in advance (i.e. before you commence your activity).</li><br>  <li>All Fees quoted are in Indian Rupees. The Zebu Share and Wealth Managements Pvt Ltd reserves the right to change the fees at any time.</li><br>  <li>Your payment will normally reach the Zebu Share and Wealth Managements Pvt Ltd account to which you are making a payment within two working days.</li><br>  <li>We cannot accept liability for a payment not reaching the correct Zebu Share and Wealth Managements Pvt Ltd account due to you quoting an incorrect account number or incorrect personal details. Neither can we accept liability if payment is refused or declined by the credit/debit card supplier for any reason.</li><br>  <li>If the card supplier declines payment the Zebu Share and Wealth Managements Pvt Ltd is under no obligation to bring this fact to your attention. You should check with your bank/credit/debit card supplier that payment has been deducted from your account.</li><br>  <li>In no event will the Zebu Share and Wealth Managements Pvt Ltd be liable for any damages whatsoever arising out of the use, inability to use, or the results of use of this site, any websites linked to this site, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.</li><br></ul>",
            },
            {
                title: "Refund Policy",
                content:
                    "<ul><li>If the Customer leaves the Zebu Share and Wealth Managements Pvt Ltd before they complete their service period, there shall be no entitlement to a refund of paid service fees.</li><br> <li>Refunds, if applicable, at the discretion of the Management, will only be made to the debit/credit card used for the original transaction. For the avoidance of doubt, nothing in this Policy shall require the Zebu Share and Wealth Managements Pvt Ltd to refund the Fees (or part thereof) unless such Fees (or part thereof) have previously been paid.</li><br></ul>",
            },
            {
                title: "Privacy Policy",
                content:
                    "<ul><li>This Privacy Policy applies to all of the products, services and websites offered by Zebu Share and Wealth Managements Pvt Ltd. Sometimes, we may post product specific privacy notices or Help Centre materials to explain our products in more detail.</li><br><li>If you have any questions about this Privacy Policy, please feel free to contact us through our website or write to us at</li><br>  <li>  Information we collect and how we use it for our Zebu Share and Wealth Managements Pvt Ltd.Changes to our Privacy Policy</li><br>  <li>Zebu Share and Wealth Managements Pvt Ltd reserves the entire right to modify/amend/remove this privacy statement anytime and without any reason. Nothing contained herein creates or is intended to create a contract/agreement between Zebu Share and Wealth Managements Pvt Ltd and any user visiting the Zebu Share and Wealth Managements Pvt Ltd website or providing identifying information of any kind.</li><br></ul> ",
            },
            {
                title: "DND Policy",
                content:
                    "If you wish to stop any further sms/email alerts/contacts from our side, all you need to do is to send an email: <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: assist@zebuetrade.com'>assist@zebuetrade.com</a> with your mobile numbers and you will be excluded from the alerts list.<br>Contact Details: <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: assist@zebuetrade.com'>assist@zebuetrade.com</a>",
            },
        ],
        panelselecte: 0,
    }),
    methods: {
         setScrollwindow() {
            window.scrollTo(0, 280)
         },
    },
}
</script>