<template>
    <div>
        <div>
            <div class="my-6 my-md-16">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-blocks">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">All Videos</p>
                        <p class="mb-0 title font-weight-regular">Our latest videos hot from the channel.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn data-aos="fade-up" medium color="#2A2A2A" class="rounded-pill mr-2 text-none brd-2"
                        outlined>Newest</v-btn>
                    <v-btn data-aos="fade-up" medium color="#2A2A2A" class="rounded-pill mr-2 text-none brd-2" outlined>Last
                        video added</v-btn>

                </v-toolbar>
                <v-toolbar dense class="elevation-0 mb-6 d-md-none">
                    <div class="pl-2 pl-sm-4">
                        <p class="mb-0 font-weight-bold secondary-infont fs-28">All Videos</p>
                        <p class="mb-0 fs-16 font-weight-regular">Our latest videos hot from the channel.</p>
                    </div>
                </v-toolbar>
                <div class="pl-4">
                    <v-row v-if="allvideos.length > 0" no-glutters class="pt-2 pt-md-6">
                        <v-col v-for="(v, a) in allvideos" :key="a" cols="12" sm="4" md="3" class="pb-0 pb-md-3">
                            <v-card @click="videoShow(val = v)" data-aos="zoom-in-up" width="100%" class="elevation-0 hcard pl-2 pl-sm-4 pr-6 pr-sm-8 px-md-0"
                                :class="a != allvideos.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                                <div class="pos-rlt">
                                    <v-card class="rounded-lg elevation-0" color="transparent">

                                        <img :src="v.stdurl" width="100%" :alt="v.stdurl">

                                    </v-card>
                                    <v-card color="#ffffff" class="elevation-0 rounded-md py-1 px-3 pos-abs abs-post3">
                                        <span class="font-weight-black subtitle-2">Explainer</span>
                                    </v-card>
                                </div>

                                <v-list-item data-aos="fade-left" class="px-0">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ v.title
                                        }}</v-list-item-title>
                                        <p class="mb-0 font-weight-regular subtitle-2">{{ v.view }} views <v-icon
                                                color="#666" size="6">mdi-checkbox-blank-circle</v-icon> {{ v.ago }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                    <v-container class="mt-6 mt-md-10">
                        <v-card v-if="pagecount" data-aos="fade-down" max-width="600px"
                            class="box-s1 rounded-xl mx-auto py-1">
                            <v-pagination @input="getVideos()" color="#000" prev-icon="mdi-arrow-left"
                                next-icon="mdi-arrow-right" v-model="page" class="my-3 elevation-0" :total-visible="11"
                                :length="pagecount"></v-pagination>
                        </v-card>
                    </v-container>
                </div>
            </div>
        </div>

        <div>
            <v-dialog v-model="videodialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-container class="pa-0" fill-height>

                    <v-card color="transparent" class="rounded-md mx-auto elevation-0 pb-0 mb-0" width="80%">
                        <v-toolbar color="transparent" dense class="tool-dialog elevation-0">
                            <div>
                                <v-list-item class="pl-0">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold title white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text">{{ playvideo ? playvideo.view : null }} views <v-icon
                                                color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                    playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon @click="videoClose()" color="#ffffff">mdi-close</v-icon>
                        </v-toolbar>
                        <div>
                            <iframe class="rounded-lg" type="text/html" width="100%" height="560px"
                                :src="`https://www.youtube.com/embed/${playvideo ? playvideo.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="videodialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                    </v-card>

                </v-container>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl'

export default {
    data: () => ({
        playvideo: null,
        videodialog: false,
        page: 1,
        shortpage: 1,

        pagecount: 0,
        allvideos: [],
    }),
    mounted() {
        this.getVideos();
        this.videoClose();
        this.scrollBehavior();
    },
    methods: {

        getVideos() {
            this.allvideos = []
            let config = {
                method: 'post',
                url: `${sessapi}/getallvideos?pagecount=${this.page}&pageSize=24`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            axiosThis.allvideos.push(data[v]);
                        }
                        axiosThis.pagecount = response.data.TotalPages;
                        axiosThis.scrollBehavior();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        scrollBehavior() {
            window.scrollTo(0, 0);
        },
        videoShow(val) {
            this.playvideo = val;
            this.videodialog = true;
        },
        videoClose() {
            this.videodialog = false;
            this.playvideo = [];
        },
    }
}
</script>