<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Investor Charter - Stock Brokers</p>
                <p class="white--text headline">Investor Charter - Stock Brokers
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Investor Charter - Stock Brokers</p>
                <p class="white--text fs-18">Investor Charter - Stock Brokers
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 px-md-14 py-md-16 mb-6">
            <p class="mb-0 font-weight-bold title"> 1. VISION</p>
            <p class="font-weight-light lh-28 ml-6 ">To follow highest standards of ethics and compliances while
                facilitating the trading
                by clients in securities in a fair and transparent manner, so as to contribute in
                creation of wealth for investors.</p>

            <p class="mb-0 font-weight-bold title"> 2. MISSION</p>


            <p class="font-weight-light lh-28 ml-6 ">
            <ul style="list-style-type: none;">
                <li>i) To provide high quality and dependable service through innovation, capacity
                    enhancement and use of technology.</li>
                <li>ii) To establish and maintain a relationship of trust and ethics with the investors.</li>
                <li>iii) To observe highest standard of compliances and transparency.</li>
                <li>iv) To always keep ‘protection of investors’ interest’ as goal while providing
                    service.</li>
                <li>v) To ensure confidentiality of information shared by investors unless such
                    information is required to be provided in furtherance of discharging legal
                    obligations or investors have provided specific consent to share such
                    information.</li>
            </ul>
            </p>
            <!-- <p class="mb-0 font-weight-bold title ml-6 mt-1">Vision</p> -->

            <!-- <p class="mb-0 font-weight-bold title mt-3"> 2) Business transacted by the Exchange with investors:
            </p>

            <p class="font-weight-light lh-28 ml-6 ">The Exchange facilitates various products for investors to
                participate across
                asset classes viz Equity, Commodities, Derivatives, Debt, Mutual Funds,
                Government securities etc. <br> <a href="https://www.nseindia.com/products-services"
                    target="_blank">NSE</a>
                <a class="ml-4" href="https://www.bseindia.com/markets.html" target="_blank">BSE</a>
                <a class="ml-4" href="https://www.mcxindia.com/products/" target="_blank">MCX</a>
            </p> -->

            <p class="mb-0 font-weight-bold title mt-3"> 3. Services provided to Investors by stockbrokers include
            </p>
            <p class="font-weight-light mb-0 lh-28 ml-6 ">
            <ul style="list-style-type: none; ">
                <li>I. Execution of trades on behalf of investors.</li>
                <li>II. Issuance of Contract Notes.</li>
                <li>III. Issuance of intimations regarding margin due payments.</li>
                <li>IV. Facilitate execution of early pay-in obligation instructions.</li>
                <li>V. Periodic Settlement of client’s funds.</li>
                <li>VI. Issuance of retention statement of funds at the time of settlement.</li>
                <li>VII. Risk management systems to mitigate operational and market risk.</li>
                <li>VIII. Facilitate client profile changes in the system as instructed by the client.</li>
                <li>IX. Information sharing with the client w.r.t. relevant Market Infrastructure
                    Institutions (MII) circulars.</li>
                <li>X. Provide a copy of Rights & Obligations document to the client.</li>
                <li>XI. Communicating Most Important terms and Conditions (MITC) to the client.</li>
                <li>XII. Redressal of Investor’s grievances.</li>
            </ul>
            </p>
            <!-- <p class="font-weight-light  ml-6 "><br>
                <a href="https://nsearchives.nseindia.com/web/sites/default/files/inline-files/SERVICES_PROVIDED_BY_THE_EXCHANGES_TO_INVESTORS.pdf"
                    target="_blank">NSE</a>
                <a class="ml-4" href="https://www.bseindia.com/investor.html" target="_blank">BSE</a>
                <a class="ml-4"
                    href="https://www.mcxindia.com/docs/default-source/investor-grievance-arbitration/investors-charter/services-to-investors.pdf?sfvrsn=bb083191_2"
                    target="_blank">MCX</a>

            </p> -->

            <p class="mb-0 font-weight-bold title mt-3"> 4. Rights of Investors
            </p>
            <p class="font-weight-light lh-28 ml-6 ">
                <ul style="list-style-type: none; padding: 0; margin: 0;">
    <li>I. Ask for and receive information from a firm about the work history and 
        background of the person handling your account, as well as information about 
        the firm itself (including website providing mandatory information).</li>
    <li>II. Receive complete information about the risks, obligations, and costs of any 
        investment before investing.</li>
    <li>III. Receive a copy of all completed account forms and rights & obligation 
        document.</li>
    <li>IV. Receive a copy of ‘Most Important Terms & Conditions’ (MITC).</li>
    <li>V. Receive account statements that are accurate and understandable.</li>
    <li>VI. Understand the terms and conditions of transactions you undertake.</li>
    <li>VII. Access your funds in a prescribed manner and receive information about any 
        restrictions or limitations on access.</li>
    <li>VIII. Receive complete information about maintenance or service charges, 
        transaction or redemption fees, and penalties in form of tariff sheet.</li>
    <li>IX. Discuss your grievances with compliance officer / compliance team / 
        dedicated grievance redressal team of the firm and receive prompt attention 
        to and fair consideration of your concerns.</li>
    <li>X. Close your zero balance accounts online with minimal documentation.</li>
    <li>XI. Get the copies of all policies (including Most Important Terms and Conditions) 
        of the broker related to dealings of your account.</li>
    <li>XII. Not be discriminated against in terms of services offered to equivalent clients.</li>
    <li>XIII. Get only those advertisement materials from the broker which adhere to Code 
        of Advertisement norms in place.</li>
    <li>XIV. In case of broker defaults, be compensated from the Exchange Investor 
        Protection Fund as per the norms in place.</li>
    <li>XV. Trade in derivatives after submission of relevant financial documents to the 
        broker subject to brokers’ adequate due diligence.</li>
    <li>XVI. Get warnings on the trading systems while placing orders in securities where 
        surveillance measures are in place.</li>
    <li>XVII. Get access to products and services in a suitable manner even if differently 
        abled.</li>
    <li>XVIII. Get access to educational materials of the MIIs and brokers.</li>
    <li>XIX. Get access to all the exchanges of a particular segment you wish to deal with 
        unless opted out specifically as per Broker norms.</li>
    <li>XX. Deal with one or more stockbrokers of your choice without any compulsion of 
        minimum business.</li>
    <li>XXI. Have access to the escalation matrix for communication with the broker.</li>
    <li>XXII. Not be bound by any clause prescribed by the Brokers which are 
        contravening the Regulatory provisions.</li>
</ul>

                </p>

            <p class="mb-0 font-weight-bold title"> 5. Various activities of Stock Brokers with timelines 
            </p>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mt-5 mb-8">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span
                            class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4"><span class="font-weight-bold subtitle-1">Activities</span></v-col>
                    <v-col cols="8" md="7"><span class="font-weight-bold subtitle-1">Expected Timelines 
                    </span></v-col>
                </v-row>
                <v-divider></v-divider>

                <div v-for="(l, j) in variosssterp5" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.id
                        }}</span></v-col>
                        <v-col cols="4"><span class="font-weight-regular txt-444" v-html="l.activity"></span></v-col>
                        <v-col cols="8" md="7"><span class="font-weight-regular txt-444"
                                v-html="l.timeline"></span></v-col>
                    </v-row>
                    <v-divider v-if="variosssterp5.length - 1 != j"></v-divider>
                </div>

            </v-card>

            <p class="mb-0 font-weight-bold title">6. DOs and DON’Ts for Investors
            </p>
          

            <v-card width="100%" class="price-card-sty mt-6 elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                  <v-col cols="1" class="d-none d-md-block"><span
                    class="font-weight-bold subtitle-1">Sr.no
                  </span></v-col>
                    <v-col cols="5" class="d-none d-md-block"><span
                            class="font-weight-bold subtitle-1">DOs</span></v-col>
                    <v-col cols="6"><span class="font-weight-bold subtitle-1">DON’Ts</span></v-col>
                    <!-- <v-col cols="8" md="7"><span class="font-weight-bold subtitle-1">Timeline</span></v-col> -->
                </v-row>
                <v-divider></v-divider>

                <div v-for="(l, j) in dodontes" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.id
                        }}</span></v-col>
                        <v-col cols="5"><span class="font-weight-regular txt-444" v-html="l.do"></span></v-col>
                        <v-col cols="6" ><span class="font-weight-regular txt-444"
                                v-html="l.dont"></span></v-col>
                    </v-row>
                    <v-divider v-if="dodontes.length - 1 != j"></v-divider>
                </div>

            </v-card>


            <p class="font-weight-light lh-28  ">Additionally, Investors may refer to Dos and Don’ts issued by MIIs on their
                respective websites from time to time.</p>



            <p class="mb-0 font-weight-bold title">7. Grievance Redressal Mechanism

            </p>

            <p class="font-weight-light lh-28 mb-0 mt-1">The process of investor grievance redressal is as follows:
            </p>

            <v-card width="100%" class="price-card-sty mt-6 elevation-0 rounded-xl overflow-hidden mb-6">
              

                <div v-for="(l, j) in grivenince" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.id
                        }}</span></v-col>
                        <v-col cols="5"><span class="font-weight-regular txt-444" v-html="l.cl1"></span></v-col>
                        <v-col cols="6" ><span class="font-weight-regular txt-444"
                                v-html="l.cl2"></span></v-col>
                    </v-row>
                    <v-divider v-if="grivenince.length - 1 != j"></v-divider>
                </div>

            </v-card>
          
          
            <p class="mb-0 font-weight-bold mt-2 title">8. Handling of Investor’s claims / complaints in case of default of a Trading
Member / Clearing Member (TM/CM)

            </p>
            <p class="mb-0 font-weight-bold title ml-6 mt-3">Default of TM/CM
            </p>

            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6">Following steps are carried out by Stock Exchange for benefit of investor, in case
stock broker defaults:

            </p>

            <p class="font-weight-light ml-4 mt-1 lh-28">
            <ul type="i">
                <li>Circular is issued to inform about declaration of Stock Broker as Defaulter.</li>
  <li>Information of defaulter stock broker is disseminated on Stock Exchange website.</li>
  <li>Public Notice is issued informing declaration of a stock broker as defaulter and inviting claims within a specified period.</li>
  <li>Intimation to clients of defaulter stock brokers via emails and SMS for facilitating lodging of claims within the specified period.</li>
            </ul>
            </p>

            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6">Following information is available on Stock Exchange website for information of
                investors:
            </p>

            <p class="font-weight-light ml-4 mt-1 lh-28">
            <ul type="i">
                <li>Norms for eligibility of claims for compensation from IPF.</li>
  <li>Claim form for lodging claim against defaulter stock broker.</li>
  <li>FAQ on processing of investors’ claims against Defaulter stock broker.</li>
  <li>Provision to check online status of client’s claim.</li>
  <li>Standard Operating Procedure (SOP) for handling of Claims of Investors in the Cases of Default by Brokers.</li>
  <li>Claim processing policy against Defaulter/Expelled members.</li>
  <li>List of Defaulter/Expelled members and public notice issued.</li>
            </ul>
            </p>



        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
      
        // --------------------------------------------------new --------------------------------------
        variosssterp5:[
        
  {
    "id": 1,
    "activity": "KYC entered into KRA System and CKYCR",
    "timeline": "3 working days of account opening"
  },
  {
    "id": 2,
    "activity": "Client Onboarding",
    "timeline": "Immediate, but not later than one week"
  },
  {
    "id": 3,
    "activity": "Order execution",
    "timeline": "Immediate on receipt of order, but not later than the same day"
  },
  {
    "id": 4,
    "activity": "Allocation of Unique Client Code",
    "timeline": "Before trading"
  },
  {
    "id": 5,
    "activity": "Copy of duly completed Client Registration Documents to clients",
    "timeline": "7 days from the date of upload of Unique Client Code to the Exchange by the trading member"
  },
  {
    "id": 6,
    "activity": "Issuance of contract notes",
    "timeline": "24 hours of execution of trades"
  },
  {
    "id": 7,
    "activity": "Collection of upfront margin from client",
    "timeline": "Before initiation of trade"
  },
  {
    "id": 8,
    "activity": "Issuance of intimations regarding other margin due payments",
    "timeline": "At the end of the T day"
  },
  {
    "id": 9,
    "activity": "Settlement of client funds",
    "timeline": "First Friday/Saturday of the month / quarter as per Exchange pre-announced schedule"
  },
  {
    "id": 10,
    "activity": "‘Statement of Accounts’ for Funds, Securities and Commodities",
    "timeline": "Monthly basis"
  },
  {
    "id": 11,
    "activity": "Issuance of retention statement of funds/commodities",
    "timeline": "5 days from the date of settlement"
  },
  {
    "id": 12,
    "activity": "Issuance of Annual Global Statement",
    "timeline": "30 days from the end of the financial year"
  },
  {
    "id": 13,
    "activity": "Investor grievances redressal",
    "timeline": "21 calendar days from the receipt of the complaint"
  }


        ],
       "dodontes": [
    {
      "id": "1",
      "do": "Read all documents and conditions being agreed before signing the account opening form.",
      "dont": "Do not deal with unregistered stock broker."
    },
    {
      "id": "2",
      "do": "Receive a copy of KYC, copy of account opening documents and Unique Client Code.",
      "dont": "Do not forget to strike off blanks in your account opening and KYC."
    },
    {
      "id": "3",
      "do": "Read the product / operational framework / timelines related to various Trading and Clearing & Settlement processes.",
      "dont": "Do not submit an incomplete account opening and KYC form."
    },
    {
      "id": "4",
      "do": "Receive all information about brokerage, fees and other charges levied.",
      "dont": "Do not forget to inform any change in information linked to trading account and obtain confirmation of updation in the system."
    },
    {
      "id": "5",
      "do": "Register your mobile number and email ID in your trading, demat and bank accounts to get regular alerts on your transactions",
      "dont": "Do not transfer funds, for the purposes of trading to anyone other than a stock broker. No payment should be made in name of employee of stock broker."
    },
    {
      "id": "6",
      "do": "If executed, receive a copy of Demat Debit and Pledge Instruction (DDPI). However, DDPI is not a mandatory requirement as per SEBI / Stock Exchanges. Before granting DDPI, carefully examine the scope and implications of powers being granted.",
      "dont": "Do not ignore any emails / SMSs received with regards to trades done, from the Stock Exchange and raise a concern, if discrepancy is observed."
    },
    {
      "id": "7",
      "do": "Receive contract notes for trades executed, showing transaction price, brokerage, GST and STT/CTT etc. as applicable, separately, within 24 hours of execution of trades.",
      "dont": "Do not opt for digital contracts, if not familiar with computers."
    },
    {
      "id": "8",
      "do": "Receive funds and securities/commodities on time, as prescribed by SEBI or exchange from time to time.",
      "dont": "Do not share trading password."
    },
    {
      "id": "9",
      "do": "Verify details of trades, contract notes and statement of account and approach relevant authority for any discrepancies. Verify trade details on the Exchange websites from the trade verification facility provided by the Exchanges.",
      "dont": "Do not fall prey to fixed / guaranteed returns schemes."
    },
    {
      "id": "10",
      "do": "Receive statement of accounts periodically. If opted for running account settlement, account has to be settled by the stock broker as per the option given by the client (Monthly or Quarterly).",
      "dont": "Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks / securities promising huge profits."
    },
    {
      "id": "11",
      "do": "In case of any grievances, approach stock broker or Stock Exchange or SEBI for getting the same resolved within prescribed timelines.",
      "dont": "Do not follow herd mentality for investments. Seek expert and professional advice for your investments."
    },
    {
      "id": "12",
      "do": "Retain documents for trading activity as it helps in resolving disputes, if they arise.",
      "dont": ""
    }
  ],
grivenince:[
    {
        "id":"1.",
        "cl1":"Investor complaint/Grievances",
        "cl2": "<div><p>Investor can lodge complaint/grievance against stock broker in the following ways:</p> <p><strong>Mode of filing the complaint with stock broker:</strong></p> <p>Investor can approach the Stock Broker at the designated Investor Grievance e-mail ID of the stock broker. The Stock Broker will strive to redress the grievance immediately, but not later than 21 days of the receipt of the grievance.</p> <p><strong>Mode of filing the complaint with stock exchanges:</strong></p> <ul> <li><strong>SCORES 2.0:</strong> A web-based centralized grievance redressal system of SEBI <a href=\"https://scores.sebi.gov.in\">https://scores.sebi.gov.in </a> <br> <u> Two-level review for complaint/grievance against stock broker: </u> <br> <ul><li>First review done by Designated body/Exchange </li><li>Second review done by SEBI </li></ul><li>Emails to designated email IDs of Exchange</li> </ul></div>"
    },
    {
  "id": 2.,
  "cl1": "Online Dispute Resolution (ODR) platform for online Conciliation and Arbitration",
  "cl2": "<p>If the Investor is not satisfied with the resolution provided by the Market Participants, then the Investor has the option to file the complaint/grievance on <strong>SMARTODR</strong> platform for its resolution through online conciliation or arbitration.</p>"
},

{
  "id": 3.,
  "cl1": "Steps to be followed in ODR for Review, Conciliation and Arbitration",
  "cl2": "<div><p>1. Investor to approach Market Participant for redressal of complaint.</p> <p>2. If investor is not satisfied with the response of Market Participant, he/she has either of the following two options:</p> <ul> <li>May escalate the complaint on SEBI SCORES portal.</li> <li>May also file a complaint on SMARTODR portal for its resolution through online conciliation and arbitration.</li> </ul> <p class='mt-4'>3. Upon receipt of complaint on SMARTODR portal, the relevant MII will review the matter and endeavor to resolve the matter between the Market Participant and investor within 21 days.</p> <p>4. If the matter could not be amicably resolved, then the matter shall be referred for conciliation.</p> <p>5. During the conciliation process, the conciliator will endeavor for amicable settlement of the dispute within 21 days, which may be extended by 10 days with consent of the parties to the dispute.</p> <p>6. If the conciliation is unsuccessful, then the investor may request to refer the matter for arbitration.</p> <p>7. The arbitration process is to be concluded by arbitrator(s) within 30 days, which is extendable by 30 days with consent of the parties to the dispute.</p></div>"
}


]

    }),
}
</script>