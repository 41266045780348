<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl text-center d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Reports</p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 text-center d-md-none">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Reports</p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 pa-md-16 mb-6 mx-md-16">
            <v-row class="py-16 py-md-0">
                <v-col cols="12" md="6">
                    <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-6 mb-4">
                        <p class="mb-0 title font-weight-regular">FY 2021-2022 <span class="float-right">
                                <a target="_blank" rel="noopener noreferrer" href="https://zebuetrade.com/asset/static/zebu/reports/MGT-7%202021-22.pdf">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path opacity="0.12" d="M3.5 24.5H24.5V17.5H3.5V24.5Z" fill="#6941C6" />
                                        <path
                                            d="M24.5 17.5V18.9C24.5 20.8602 24.5 21.8403 24.1185 22.589C23.783 23.2475 23.2475 23.783 22.589 24.1185C21.8403 24.5 20.8602 24.5 18.9 24.5H9.1C7.13982 24.5 6.15972 24.5 5.41103 24.1185C4.75247 23.783 4.21703 23.2475 3.88148 22.589C3.5 21.8403 3.5 20.8602 3.5 18.9V17.5M19.8333 11.6667L14 17.5M14 17.5L8.16667 11.6667M14 17.5V3.5"
                                            stroke="#0037B7" stroke-width="3.33333" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg></a>
                            </span></p>
                    </v-card>
                </v-col>
            </v-row>

        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
    }),
}
</script>